import backgroundNewYear1 from '../../assets/themes/New Year 1/bgr.png';
import progressBarNewYear from '../../assets/themes/New Year 1/progressbar-new-year.png';
import backgroundBookNewYear1 from '../../assets/themes/New Year 1/book1.png';
import backgroundBookNewYear1Small from '../../assets/themes/New Year 1/book1-new-year-1.png';
import buttonCloseLeftNewYear from '../../assets/themes/New Year 1/btn-close.png';
import iconNextNewYear1 from '../../assets/themes/New Year 1/next.png';
import iconPreNewYear1 from '../../assets/themes/New Year 1/back.png';
import iconTimeRightNewYear from '../../assets/themes/New Year 1/box-time-header-right.png';
import iconCloseLeftNewYear from '../../assets/themes/New Year 1/box-time-header-left.png';
import backgroundProgressBarWrapNewYear from '../../assets/themes/New Year 1/progress-bar.webp';
import iconNearBookLeftNewYear1 from '../../assets/themes/New Year 1/kylan-left.png';
import iconNearBookRightNewYear1 from '../../assets/themes/New Year 1/kylan-right.png';
import backgroundNewYear2 from '../../assets/themes/New Year 2/bgr.png';
import backgroundBookNewYear2 from '../../assets/themes/New Year 2/book1.png';
import backgroundBookNewYear2Small from '../../assets/themes/New Year 2/book1-new-year-2.png';
import buttonCloseLeftNewYear2 from '../../assets/themes/New Year 2/bt-close.png';
import iconNextNewYear2 from '../../assets/themes/New Year 2/next.png';
import iconPreNewYear2 from '../../assets/themes/New Year 2/back.png';
import iconTimeRightNewYear2 from '../../assets/themes/New Year 2/box-time-header-right.d894bb53.png';
import iconCloseLeftNewYear2 from '../../assets/themes/New Year 2/box-time-header-right.d894dsbb53.png';
import backgroundProgressBarWrapNewYear2 from '../../assets/themes/New Year 2/progress-bar-back-ground.6131720a.webp';
import iconNearBookLeftNewYear2 from '../../assets/themes/New Year 2/cat-left.png';
import iconNearBookRightNewYear2 from '../../assets/themes/New Year 2/cat-right.png';
// type default or challenge
export const THEME_OPTION = [
  {
    id: 97,
    name: 'New year 1',
    type: 'default',
    challengeMode: false,
    background: backgroundNewYear1,
    backgroundBookMultiChose: backgroundBookNewYear1Small,
    backgroundBookLager: backgroundBookNewYear1,
    buttonCloseLeft: buttonCloseLeftNewYear,
    iconNext: iconNextNewYear1,
    iconPre: iconPreNewYear1,
    backgroundTimeRight: iconTimeRightNewYear,
    backgroundCloseLeft: iconCloseLeftNewYear,
    hasIconCloseLeft: false,
    backgroundProgressBar: progressBarNewYear,
    backgroundProgressBarWrap: backgroundProgressBarWrapNewYear,
    iconNearBookLeft: iconNearBookLeftNewYear1,
    iconNearBookRight: iconNearBookRightNewYear1,
  },
  {
    id: 96,
    name: 'New year 2',
    type: 'default',
    challengeMode: false,
    background: backgroundNewYear2,
    backgroundBookMultiChose: backgroundBookNewYear2Small,
    backgroundBookLager: backgroundBookNewYear2,
    buttonCloseLeft: buttonCloseLeftNewYear2,
    iconNext: iconNextNewYear2,
    iconPre: iconPreNewYear2,
    backgroundTimeRight: iconTimeRightNewYear2,
    backgroundCloseLeft: iconCloseLeftNewYear2,
    hasIconCloseLeft: false,
    backgroundProgressBar: progressBarNewYear,
    backgroundProgressBarWrap: backgroundProgressBarWrapNewYear2,
    iconNearBookLeft: iconNearBookLeftNewYear2,
    iconNearBookRight: iconNearBookRightNewYear2,
  },
];
//
// {
//   id: 100,
//       name: 'Xmas 1',
//     type: 'default',
//     challengeMode: false,
//     background: backgroundXmas1,
//     backgroundBookMultiChose: backgroundBookXmas1,
//     backgroundBookLager: backgroundBookLager,
//     buttonCloseLeft: buttonCloseLeft,
//     iconNext: iconNextXmas1,
//     iconPre: iconPreXmas1,
//     backgroundTimeRight: iconTimeRightXmas1,
//     backgroundCloseLeft: iconCloseLeftXmas1,
//     hasIconCloseLeft: false,
//     backgroundProgressBar: backgroundProgressBar,
//     backgroundProgressBarWrap: backgroundProgressBarWrapXmas1,
//     iconNearBookLeft: null,
//     iconNearBookRight: null,
// },
// {
//   id: 99,
//       name: 'Xmas 2',
//     type: 'default',
//     challengeMode: false,
//     background: backgroundXmas2,
//     backgroundBookMultiChose: backgroundBookXmas2,
//     backgroundBookLager: backgroundBookLagerXmas2,
//     buttonCloseLeft: buttonCloseLeft,
//     iconNext: iconNextXmas2,
//     iconPre: iconPreXmas2,
//     backgroundTimeRight: iconTimeRightXmas2,
//     backgroundCloseLeft: iconCloseLeftXmas2,
//     hasIconCloseLeft: false,
//     backgroundProgressBar: backgroundProgressBarXmas,
//     backgroundProgressBarWrap: backgroundProgressBarWrapXmas2,
//     iconNearBookLeft: null,
//     iconNearBookRight: null,
// },
// {
//   id: 98,
//       name: 'Xmas 3',
//     type: 'default',
//     challengeMode: false,
//     background: backgroundXmas3,
//     backgroundBookMultiChose: backGroundBook3Small,
//     backgroundBookLager: backGroundBook3,
//     buttonCloseLeft: buttonCloseLeft,
//     iconNext: iconNextXmas2,
//     iconPre: iconPreXmas2,
//     backgroundTimeRight: iconTimeRightXmas2,
//     backgroundCloseLeft: iconCloseLeftXmas2,
//     hasIconCloseLeft: false,
//     backgroundProgressBar: backgroundProgressBarXmas,
//     backgroundProgressBarWrap: backgroundProgressBarWrapXmas2,
//     iconNearBookLeft: null,
//     iconNearBookRight: null,
// },
