import { defaultLocale, currentLocale } from '../i18n';

function getUrlService(service) {
  return `${window.location.origin.replace('exam-old', service)}${
    currentLocale !== defaultLocale ? `/${currentLocale}` : ''
  }`;
}

export const ROOT_DOMAIN_URL = (function () {
  const parts = window.location.host.split('.');

  return parts
    .slice(parts.length - 2)
    .join('.')
    .split(':')[0];
})();

export const REPORT_URL = process.env.REACT_APP_REPORT_URL || getUrlService('report');

export const SPEAKING_URL = process.env.REACT_APP_SPEAKING_URL || getUrlService('speaking');
export const SPEAKING_URL_US = process.env.REACT_APP_SPEAKING_URL_US || getUrlService('speaking');

export const WRITING_URL = process.env.REACT_APP_WRITING_URL || getUrlService('writing');

export const RANKING_URL = process.env.REACT_APP_RANKING_URL || getUrlService('ranking');
export const MAIN_URL = process.env.REACT_APP_MAIN_NEW_URL || 'https://exam.flyer.vn';
export const MAIN_URL_DYNAMIC = window.location.origin.replace('exam-old', 'exam');
export const MAIN_DEV_URL = 'https://dev.exam.flyer.vn';
export const FULL_URL = window.location.href;
export const handlerClickBack = (queryString) => {
  const urlBack = localStorage.getItem('url_back');
  if (queryString && queryString !== '' && urlBack) {
    return window.location.assign(urlBack + '/' + queryString);
  }
  if (localStorage.getItem('url_back')) {
    return (window.location.href = urlBack);
  }
  return window.location.href?.includes('localhost')
    ? window.location.assign('http://localhost:3003/exam')
    : window.location.assign(MAIN_URL_DYNAMIC);
};
