import React from 'react';
import { useAtom } from 'jotai';
import { orderBy } from 'lodash';
import { listThemeOptionAtom, themeSelectEdAtom } from '../../hook/common';

const ChangeTheme = ({ isChallenge }) => {
  const [listThemeOptions] = useAtom(listThemeOptionAtom);
  const [themeSelectEd, setThemeSelectEd] = useAtom(themeSelectEdAtom);

  return (
    <>
      <div className="theme-dropdown" kr-inert="false" kr-inerted="false" aria-hidden="false">
        {!!listThemeOptions &&
          orderBy(listThemeOptions, 'id', 'desc').map((item) => {
            return (
              <button
                className={`theme ${item.id === themeSelectEd ? 'selected' : ''} ${
                  isChallenge ? 'challenge-mode' : 'not-challenge-mode'
                }`}
                data-theme={item.name}
                role="radio"
                onClick={() => setThemeSelectEd(item.id)}
                key={item.id}
              >
                <div className="theme-icon">
                  <img src={item.background} alt="" />
                </div>
                <div className="theme-name">{item.name}</div>
                <div className="radio-btn">
                  <div className="radio"></div>
                </div>
              </button>
            );
          })}
      </div>
    </>
  );
};
export default ChangeTheme;
