import { css, styled } from 'goober';
import React, { useRef, useState } from 'react';
import moversTitleLevel from '../../../../assets/images/st-movers.png';
import ieltsTitleLevel from '../../../../assets/images/st-ielts.png';
import ketTitleLevel from '../../../../assets/images/st-ket.png';
import starterTitleLevel from '../../../../assets/images/st-starter.png';
import flyerTitleLevel from '../../../../assets/images/st-flyer.png';
import defaultTitleLevel from '../../../../assets/images/st-title-checkpoint.png';
import toeflJuniorTitleLevel from '../../../../assets/images/st-toefl-junior.png';
import titleBodyModal from '../../../../assets/images/createChallenge/bg-title-box.png';
import bgTitle from '../../../../assets/images/createChallenge/bg-title-small.png';
import { useTranslation } from 'react-i18next';
import { sumBy } from 'lodash';
import dayjs from 'dayjs';
import interval from 'postgres-interval';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import ReactTooltip from 'react-tooltip';

const ChallengeInfoContainer = styled('div')`
  position: relative;
  z-index: 1;
  width: 26.5vw;
  max-height: 27vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -1.7vw;
  z-index: 22;
`;

const HeaderTitlePopCreateChallenge = css`
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 2.088vw;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin: 0;
`;

const HeaderTitleImagePopCreateChallenge = css`
  position: absolute;
  width: 10vw;
  height: 10vw;
  left: -0.5vw;
  top: 50%;
  margin-top: -5.6vw;
`;

const HeaderTitleTextPopCreateChallenge = css`
  font-size: 1.388vw;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin-left: 9.5vw;
  text-transform: uppercase;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
  padding-right: 1vw;
`;

const PopTitleCreateChallenge = (props) =>
  css({
    position: 'absolute',
    top: ' -2.3vw',
    left: '0',
    background: `url(${props.titleBodyModal}) no-repeat 0/100%;`,
    width: '23vw',
    height: '4vw',
  });

const Content = styled('div')`
  position: relative;
  margin-top: 3vw;
  width: 20.577vw;
  height: 20vw;
  border-radius: 5px;
  border: 1px solid #00f6ff;
`;
const Title = styled('div')`
  position: absolute;
  background: url(${bgTitle}) 0/100% no-repeat;
  width: 18.5vw;
  height: 3vw;
  left: 50%;
  transform: translateX(-50%);
  top: -1.5vw;
  padding: 0.5vw;
`;

const ContentExam = styled('div')`
  height: 50%;
  border-bottom: 1px solid #00f6ff;
  padding: 1.2vw;
  padding-top: 1.8vw;

  &:last-child {
    border-bottom: none;
    padding-top: 1vw;

    & > p {
      margin-bottom: 0.5vw;
    }
  }
`;

const textStyle = (props) =>
  css({
    textTransform: 'uppercase',
    color: props.color || '#FFFFFF',
    margin: 0,
    fontSize: props.fs || '0.9114583333333334vw',
    lineHeight: props.lineHeight,
    fontWeight: props.fw,
  });

const ContentBox = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const checkPointList = [
  {
    name: 'TOEFL PRIMARY',
    imageUrl: defaultTitleLevel,
  },
  {
    name: 'KET',
    imageUrl: ketTitleLevel,
  },
  {
    name: 'Movers',
    imageUrl: moversTitleLevel,
  },
  {
    name: 'IELTS',
    imageUrl: ieltsTitleLevel,
  },
  {
    name: 'Starters',
    imageUrl: starterTitleLevel,
  },
  {
    name: 'Flyers',
    imageUrl: flyerTitleLevel,
  },
  {
    name: 'Toefl Junior',
    imageUrl: toeflJuniorTitleLevel,
  },
];

export default function ChallengeInfo({ checkPoint, examStructures, maxScoreByExam, isPractice }) {
  const { t, i18n } = useTranslation(['test', 'create_challenge']);
  const triggerRef = useRef(null);

  const getImageCheckpoint = React.useCallback(() => {
    return checkPointList.find(
      (item) => item?.name?.toUpperCase() === checkPoint?.testLevel?.name?.toUpperCase(),
    );
  }, [checkPoint?.testLevel?.name]);

  const onHoverText = React.useCallback(() => {
    const target = triggerRef.current;
    if (
      target &&
      (target.offsetWidth < target.scrollWidth || target.offsetHeight + 1 < target.scrollHeight)
    ) {
      ReactTooltip.show(triggerRef.current);
    }
  }, []);

  return (
    <ChallengeInfoContainer>
      <div
        className={`${PopTitleCreateChallenge({
          titleBodyModal,
        })}`}
      >
        <h3 className={HeaderTitlePopCreateChallenge}>
          <img
            className={HeaderTitleImagePopCreateChallenge}
            src={getImageCheckpoint()?.imageUrl || defaultTitleLevel}
            alt=""
          />
          <p className={HeaderTitleTextPopCreateChallenge}>{checkPoint?.testLevel?.name}</p>
        </h3>
      </div>
      <Content>
        <Title>
          <span
            className={`text-one-line ${css({
              textAlign: 'center',
              textTransform: 'uppercase',
              fontSize: '0.74vw',
              fontWeight: 'bold',
              color: '#00f6ff',
              margin: '0 auto',
              lineHeight: '1vw',
              position: 'relative',
            })}`}
            ref={triggerRef}
            data-tip={
              isPractice
                ? t('practice')
                : i18n.language === 'en'
                ? checkPoint?.name_en || '--'
                : checkPoint?.name || '--'
            }
            onMouseOver={onHoverText}
          >
            {isPractice
              ? t('practice')
              : i18n.language === 'en'
              ? checkPoint?.name_en || '--'
              : checkPoint?.name || '--'}{' '}
          </span>
          <p
            className={css({
              textTransform: 'uppercase',
              fontSize: '0.6vw',
              textAlign: 'center',
              color: '#FFFFFF',
              margin: '0',
            })}
          >
            {`${t('test_section1')} ${t('test_section2', {
              count: examStructures?.length || '--',
            })} - ${t('minute_sum1')} ${t('minute_sum2', {
              count: sumBy(examStructures, 'executionTime') || '--',
            })}`}
          </p>
        </Title>
        {examStructures?.length > 0 &&
          examStructures?.map((exam, i) => (
            <ContentExam
              key={i}
              className={css({
                paddingTop: examStructures?.length === 1 && '2vw !important',
              })}
            >
              <p
                className={css({
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontSize: '1.2vw',
                  fontWeight: 'bold',
                  color: '#00f6ff',
                  margin: 0,
                  lineHeight: '1vw',
                })}
              >
                {exam?.examType?.replaceAll('_', ' - ') || '--'}
              </p>
              <ContentBox>
                <div className={css({ textAlign: 'center' })}>
                  <p className={textStyle({})}>{t('maximum_score_chal')}</p>
                  <p
                    className={textStyle({
                      fs: '4vw',
                      lineHeight: '4vw',
                      color: '#00f6ff',
                      fw: 'bold',
                    })}
                  >
                    {t('scorenotitle', {
                      count: sumBy(maxScoreByExam[exam?.examType], 'maxScore'),
                    })}
                  </p>
                  <p className={textStyle({ fw: 'bold' })}>{t('score')}</p>
                </div>

                <div className={css({ textAlign: 'center' })}>
                  <p className={textStyle({})}>{t('duration_test_chal')}</p>
                  <p
                    className={textStyle({
                      fs: '4vw',
                      lineHeight: '4vw',
                      color: '#00f6ff',
                      fw: 'bold',
                    })}
                  >
                    {t('minutenotext', {
                      count:
                        checkPoint?.type === 'mini'
                          ? dayjs
                              .duration(interval(checkPoint?.time).toISOStringShort())
                              .asMinutes()
                              .toFixed(1)
                          : exam?.executionTime,
                    })}
                  </p>
                  <p className={textStyle({ fw: 'bold' })}>{t('minute')}</p>
                </div>
              </ContentBox>
            </ContentExam>
          ))}
      </Content>
      <ReactTooltip place="top" globalEventOff="click" />
    </ChallengeInfoContainer>
  );
}
