import React from 'react';
import BgAvatar from '../../../../assets/images/rank/bg-avatar-other.png';
import defaultAvatar from '../../../../assets/images/ico-avatar-default.png';
import BgInputCenter from '../../../../assets/images/rank/bg-name.png';
import { css, styled } from 'goober';
import getMediaUrl from '../../../../../utils/helpers/getMediaUrl';

const UserAvatar = styled('div')`
  position: absolute;
  left: -1vw;
  z-index: 1;
  width: 4.348vw;
  height: 3.351vw;

  & > img {
    height: 100%;
  }
`;
const Avatar = styled('div')`
  position: absolute;
  right: 0.52vw;
  width: 2.5vw;
  height: 2.5vw;
  top: 0.47vw;
  border-radius: 50%;
  & img {
    border-radius: 50%;
    width: 2.6vw;
    height: 2.6vw;
    object-fit: cover;
  }
`;
const UserItem = styled('div')`
  position: relative;
`;
const UserInfo = styled('div')`
  position: relative;
  border: none;
  font-family: 'OpenSans-Regular';
  color: rgb(254, 254, 254);
  line-height: 1vw;
  width: inherit;
  min-width: 2vw;
  font-weight: bold;
  background: url(${BgInputCenter}) no-repeat 0/100% !important;
  background-size: 100% 100% !important;
  height: 3vw;
`;
const UserInfoText = css`
  font-size: 0.815vw;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 3vw;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding-left: 3.7vw;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 13.258vw;
  display: block;
  white-space: nowrap;
  b {
    font-width: 400;
    color: #00f6ff;
  }
`;
const UserNumber = css`
  font-size: 1.042vw;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  position: absolute;
  left: -0.45vw;
  bottom: 0.225vw;
  z-index: 2;
`;
const UserInfoCss = css`
  margin-bottom: 1vw;
`;
const WrapperAvatarCls = (props) =>
  css({
    bottom: props.bottom,
  });
export default function AccountInfo({ userInfo, ranking, currentProfile }) {
  let currentAsUser = '';
  if (currentProfile?.id.toString() === userInfo.id.toString()) {
    currentAsUser = '(you)';
  }
  return (
    <UserItem className={UserInfoCss}>
      <span className={UserNumber}>{ranking + 1}</span>
      <UserAvatar
        className={WrapperAvatarCls({
          bottom: '0vw',
        })}
      >
        <img alt="avatar-border" src={BgAvatar} />
        <Avatar>
          <img alt="avatar" src={getMediaUrl(userInfo.avatarUrl, 'avatar') || defaultAvatar} />
        </Avatar>
      </UserAvatar>
      <UserInfo>
        <span className={UserInfoText}>
          {userInfo.displayName}
          <b> {currentAsUser}</b>
        </span>
      </UserInfo>
    </UserItem>
  );
}
