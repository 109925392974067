import React, { createRef } from 'react';
import getMediaUrl from '../../../../utils/helpers/getMediaUrl';
import defaultAvatar from '../../../assets/images/ico-avatar-default.png';

const ItemUser = React.forwardRef(
  ({ profile, rank, isSessionStartEd, getImageRank, player }, ref) => {
    /*
       30 is with process
     */
    const leftTruePercent = (32 * player.answerCorrect) / player.totalQuestion;
    const rightFalse = (32 * player.answerWrong) / player.totalQuestion;
    return (
      <div key={profile.id} className="item" ref={ref}>
        <span className="number">{rank}</span>
        {rank <= 3 && isSessionStartEd ? (
          <img src={getImageRank(rank)} alt="" className="img-ranking" />
        ) : (
          <div className="image-null"></div>
        )}
        <img
          src={profile.avatarUrl ? getMediaUrl(profile.avatarUrl, 'avatar') : defaultAvatar}
          alt="avatar"
          className="avatar"
        />
        <span className="title-name">{profile.displayName}</span>
        <span
          className={`process ${
            isSessionStartEd && player.answerCorrect === 0 && player.answerWrong !== 0
              ? 'false-all-is-process'
              : ''
          }
          ${
            isSessionStartEd && player.answerWrong === 0 && player.answerCorrect !== 0
              ? 'true-all-is-process'
              : ''
          }
        
        `}
        >
          {/*// for content and no content*/}
          <>
            <span
              className={`false 
              ${
                isSessionStartEd &&
                player.answerCorrect === player.totalQuestion &&
                player.answerWrong !== player.totalQuestion
                  ? 'true-all'
                  : ''
              } 
              ${isSessionStartEd && player.answerWrong === player.totalQuestion ? 'all' : ''}
              `}
              style={
                !rightFalse || !isSessionStartEd
                  ? { opacity: '0' }
                  : { width: `calc( ${rightFalse}vw)` }
              }
            >
              {player.answerWrong}
            </span>
            <span
              className={`true ${
                isSessionStartEd &&
                player.answerWrong === player.totalQuestion &&
                player.answerCorrect !== player.totalQuestion
                  ? 'false-all'
                  : ''
              } 
              ${isSessionStartEd && player.answerCorrect === player.totalQuestion ? 'all' : ''}
              `}
              style={
                !leftTruePercent || !isSessionStartEd
                  ? { opacity: '0' }
                  : { width: `calc( ${leftTruePercent}vw)` }
              }
            >
              {player.answerCorrect}
            </span>
          </>

          <span className="no-score">
            {' '}
            {player.percentAnswer ? Number.parseFloat(player.percentAnswer).toFixed(2) : 0}%
          </span>
        </span>
        <span className="score">{player.totalScore || 0}</span>
      </div>
    );
  },
);
export default ItemUser;
