import React from 'react';
import loading from '../../assets/animation/loading.json';

const spinePlayerConfig = {
  showControls: false,
  alpha: true,
  backgroundColor: '#00000000',
  animation: 'animation',
  viewport: {
    debugRender: false,
    x: 0,
    y: 0,
    padLeft: '3px',
    padRight: '3px',
    padTop: '3px',
    padBottom: '3px',
  },
};

const PageLoader = () => {
  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    new window.spine.SpinePlayer('flyer-loading-animated', {
      ...loading,
      ...spinePlayerConfig,
    });
  }, []);

  return (
    <div className="wrapper-mover">
      <div className="full-background active"></div>
      <div id="flyer-loading-animated" className="loading-mover active"></div>
    </div>
  );
};
export default PageLoader;

// const PageLoader = () => {

//   return (
//     <div className="wrapper-mover">
//       <div className="full-background active"></div>
//       <div id="flyer-loading-animated" className="loading-mover active">
//         <span className="img-circle"></span>
//         <span className="img-logo animated infinite pulse slower"></span>
//         <span className="img-human animated infinite slideInDown-h slower"></span>
//       </div>
//     </div>
//   );
// };
// export default PageLoader;
