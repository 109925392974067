import { styled } from 'goober';
import React from 'react';
import backgroundButtonCreate from '../../../../assets/images/createChallenge/button-create.png';

const Button = styled('button')`
  position: relative;

  width: 6.5vw;
  height: 6.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;

  :disabled {
    opacity: 0.8;
  }

  & img {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  & p {
    color: #ffffff;
    z-index: 1;
    font-size: 1.2vw;
    max-width: 5vw;
    text-align: center;

    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.3vw;
    margin: 0;
    transition: 0.3s;
  }
  & p:hover {
    color: #00f6ff;
    transition: 0.3s;
  }
`;

export default function CreateChallengeButton({
  onClick,
  disabled,
  type = 'submit',
  text = 'submit',
  isShowHover,
  dataHover,
}) {
  return (
    <span data-tip={isShowHover ? dataHover : ''}>
      <Button onClick={onClick} type={type} disabled={disabled}>
        <img alt="button-create-challenge" src={backgroundButtonCreate} />
        <p>{text}</p>
      </Button>
    </span>
  );
}
