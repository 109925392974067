import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AUTH_STATE from '../utils/constants/auth-state';
import useAuth from '../hooks/use-auth';

export default function RedirectedRoute({ children, ...rest }) {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return children;
      }}
    />
  );
}
