import React from 'react';
// import { useMutation, useSubscription, gql } from '@apollo/client';
// import dayjs from 'dayjs';
// import firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';

import { AlertComponent } from '../components/common/alert-component';
// import Title from '../components/common/Title';
// import useAuth from '../hooks/use-auth';
// import useLogout from '../hooks/use-logout';
// import { SIGN_OUT } from '../graphql/mutation';
// import PageLoader from '../components/public/page-loader';
import { isMobileOrTablet } from '../utils/helpers/userAgent';

export default function SessionsCheckComponent() {
  // const { user, isSessionValid, reloadUser, authTime, isCheckSession } =
  //   useAuth();
  const { t, i18n } = useTranslation(['home', 'common', 'validate_login']);

  // const [signOut] = useMutation(SIGN_OUT);
  // const { logout, loading: logOutLoading } = useLogout();
  // const [sendEmailLoading, setSendEmailLoading] = React.useState(false);

  // useEffect(() => {
  //   if (!isSessionValid) {
  //     window.dataLayer.push({
  //       email: user?.email,
  //       display_name: user?.profiles[0]?.displayName,
  //       phone: user?.phone,
  //     });
  //   }
  // }, [isSessionValid, user]);

  // useSubscription(
  //   gql`
  //     subscription {
  //       sessions {
  //         auth_time
  //       }
  //     }
  //   `,
  //   {
  //     onSubscriptionData: ({ subscriptionData: { data, error } }) => {
  //       if (!isCheckSession.current) {
  //         isCheckSession.current = true;
  //         return;
  //       }
  //       if (!data.sessions.some((session) => dayjs(session.auth_time).isSame(authTime))) {
  //         isCheckSession.current = false;
  //         alert('Bạn đã bị logout bởi một thiết bị khác!');
  //         firebase.auth().signOut().catch(console.log);
  //       }
  //     },
  //     skip: !isSessionValid,
  //   },
  // );

  const [showDeviceModal, setShowDeviceModal] = React.useState(isMobileOrTablet());

  return (
    <>
      {/* {logOutLoading && <PageLoader />} */}
      {showDeviceModal && (
        <AlertComponent zIndexInc={999999}>
          <div
            className={`popup-thongbao2 active ${
              i18n.language === 'vi' ? 'popup-thongbao2-vn' : ''
            }`}
          >
            <div className="text-center box-confirm-text text-uppercase">
              {t('validate_login:device_login_warning')}
            </div>

            <span className="btn btn-start" onClick={() => setShowDeviceModal(false)}>
              {t('common:ok')}
            </span>
          </div>
        </AlertComponent>
      )}
    </>
  );
}
