import { useState, useEffect } from 'react';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
const isMaxMobileWidth = 640;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  const isMobileResolution = width < isMaxMobileWidth;
  return {
    width,
    height,
    isMobileResolution,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const themeSelectEdAtom = atomWithStorage('themeSelectEd', 0);
export const listThemeOptionAtom = atomWithStorage('listThemeOption', []);
export const handlerOnOffSoundAtom = atomWithStorage('soundSetting', true);
