import { styled } from 'goober';
const ButtonNoBg = styled('div')`
  background: ${(props) => (props.color ? props.color : '#7b61ff')};
  border-radius: 0.45vw;
  height: 2.3vw;
  margin-top: 1vw;
  padding: 0.4vw 0.8vw;
  display: flex;
  align-items: center;
  box-shadow: inset 0px -0.25vw 1px rgba(0, 0, 0, 0.25);
  & span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 0.7vw;
    text-align: center;
    color: #ffffff;
  }
  & img {
    padding-right: 5px;
    width: 1.5vw;
  }
`;

const ButtonNoImage = ({ title, icon, color, onClickButton }) => {
  return (
    <ButtonNoBg color={color} onClick={onClickButton}>
      {icon && <img src={icon} alt="flyer image" id="id-button-icon" />}
      <span id="id-button-text"> {title}</span>
    </ButtonNoBg>
  );
};
export default ButtonNoImage;
