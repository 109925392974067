import { gql } from '@apollo/client';

export const CREATE_ANONYMOUS_USER = gql`
  mutation ($object: profiles_insert_input!) {
    insert_profiles_one(object: $object) {
      id
    }
  }
`;

export const LEFT_CLASS = gql`
  mutation ($ids: [String!]!) {
    studentsDelete(ids: $ids)
  }
`;
// export const CHANGE_PHONE_NUMBER = gql`
//   mutation ($otpCode: String!, $password: String!, $phoneNumber: String!, $sessionInfo: String!) {
//     phoneNumberSet(
//       input: {
//         otpCode: $otpCode
//         password: $password
//         phoneNumber: $phoneNumber
//         sessionInfo: $sessionInfo
//       }
//     ) {
//       userErrors {
//         code
//         field
//         message
//       }
//     }
//   }
// `;

// export const CHANGE_PASSWORD = gql`
//   mutation ($password: String!, $newPassword: String!) {
//     passwordSet(input: { password: $password, newPassword: $newPassword }) {
//       userErrors {
//         code
//         field
//         message
//       }
//     }
//   }
// `;

// export const SEND_OTP = gql`
//   mutation ($captchaToken: String!, $phoneNumber: String!) {
//     otpSend(captchaToken: $captchaToken, phoneNumber: $phoneNumber) {
//       sessionInfo
//       userErrors {
//         code
//         message
//         field
//       }
//     }
//   }
// `;

// export const VERIFY_OTP = gql`
//   mutation ($code: String!, $phoneNumber: String!, $sessionInfo: String!) {
//     otpVerify(phoneNumber: $phoneNumber, code: $code, sessionInfo: $sessionInfo) {
//       userErrors {
//         code
//         field
//         message
//       }
//       phoneNumber
//     }
//   }
// `;

// export const LOG_IN_WITH_PASSWORD = gql`
//   mutation ($input: LogInWithPasswordInput!) {
//     logInWithPassword(input: $input) {
//       userErrors {
//         code
//         field
//         message
//       }
//     }
//   }
// `;

// export const REGISTER_WITH_PASSWORD = gql`
//   mutation ($input: RegisterWithPasswordInput!) {
//     registerWithPassword(input: $input) {
//       userErrors {
//         code
//         field
//         message
//       }
//     }
//   }
// `;

// export const OTP_SEND = gql`
//   mutation ($captchaToken: String!, $phoneNumber: String!) {
//     otpSend(captchaToken: $captchaToken, phoneNumber: $phoneNumber) {
//       phoneNumber
//       sessionInfo
//       userErrors {
//         code
//         field
//         message
//       }
//     }
//   }
// `;

// export const PASSWORD_RECOVER_BY_PHONE = gql`
//   mutation forgotPassword($input: PasswordRecoverByPhoneNumberInput!) {
//     passwordRecoverByPhoneNumber(input: $input) {
//       userErrors {
//         code
//         field
//         message
//       }
//     }
//   }
// `;

// export const VERIFY_EMAIL = gql`
//   mutation ($email: String!) {
//     emailSet(input: { email: $email }) {
//       userErrors {
//         code
//         field
//         message
//       }
//     }
//   }
// `;

// export const CREATE_LEARNER_PROFILE = gql`
//   mutation createLearnerProfile($input: LearnerProfileCreateInput!) {
//     learnerProfileCreate(input: $input) {
//       userErrors {
//         code
//         field
//         message
//       }
//       learnerProfile {
//         avatarUrl
//         dateOfBirth
//         displayName
//         firstName
//         id
//         gender
//         lastName
//       }
//     }
//   }
// `;

// export const UPDATE_LEARNER_PROFILE = gql`
//   mutation updateLearnerProfile($id: ID!, $input: LearnerProfileUpdateInput!) {
//     learnerProfileUpdate(id: $id, input: $input) {
//       learnerProfile {
//         avatarUrl
//         dateOfBirth
//         displayName
//         firstName
//         gender
//         id
//         lastName
//         school {
//           id
//           name
//           province {
//             id
//             name
//           }
//         }
//       }
//       userErrors {
//         code
//         field
//         message
//         param {
//           value
//         }
//       }
//     }
//   }
// `;

export const CLASS_STUDENT_JOIN_ClASS = gql`
  mutation addStudentByClassCode($code: String!, $studentId: String!) {
    classStudentJoinByCode(code: $code, studentId: $studentId) {
      id
      code
      name
    }
  }
`;

// export const CREATE_CHALLENGE_ANONYMOUS_USER = gql`
//   mutation learnerProfileCreate($input: LearnerProfileCreateInput!) {
//     learnerProfileCreate(input: $input) {
//       learnerProfile {
//         id
//         displayName
//         isAnonymousUser: isAnonymous
//       }
//       userErrors {
//         code
//         field
//         message
//         param {
//           value
//         }
//       }
//     }
//   }
// `;

// export const CREATE_CHALLENGE = gql`
//   mutation ($input: ChallengeCreateInput!) {
//     challengeCreate(input: $input) {
//       challenge {
//         id
//         code
//         learnerProfile {
//           id
//         }
//         checkPoint {
//           id
//         }
//         settings {
//           sound
//         }
//       }
//       userErrors {
//         code
//       }
//     }
//   }
// `;

// export const UPDATE_ANONYMOUS_USER = gql`
//   mutation learnerProfileUpdate($id: ID!, $firstName: String!) {
//     learnerProfileUpdate(id: $id, input: { firstName: $firstName }) {
//       learnerProfile {
//         id
//         displayName
//         isAnonymousUser: isAnonymous
//       }

//       userErrors {
//         code
//       }
//     }
//   }
// `;

export const PLAYER_JOIN_CHALLENGE = gql`
  mutation ($challengeId: bigint, $learnerProfileId: bigint) {
    insert_challenge_players_one(
      object: { challenge_id: $challengeId, learner_profile_id: $learnerProfileId }
      on_conflict: {
        constraint: challenge_players_challenge_id_learner_profile_id_key
        update_columns: []
      }
    ) {
      id
    }
  }
`;

export const END_SESSION_CHALLENGE = gql`
  mutation challengeUpdate($id: bigint!, $input: challenges_set_input!) {
    challenge: update_challenges_by_pk(pk_columns: {id: $id}, _set: $input) {
      id
      endAt: end_at
      code
      createdAt: created_at
      name
    }
  }
`;

export const SUBMIT_ASSIGN_TEST = gql`
  mutation submitAssignTest($profileId: String!, $assignTestId: String!) {
    submitAssignTest(assignTestId: $assignTestId, profileId: $profileId) {
      created_at
    }
  }
`;
