import { gql } from '@apollo/client';

export const SUBSCRIPTION_CHALLENGE = gql`
  subscription ($where: challenges_bool_exp!) {
    challenges(where: $where) {
      id
      name
      code
      settings
      endAt: end_at
      __typename
    }
  }
`;
