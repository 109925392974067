import React from 'react';
import { css, styled } from 'goober';
import goBackButton from '../../assets/images/createChallenge/button-back.png';
import backIcon from '../../assets/images/createChallenge/left.png';
import backgroundTitle from '../../assets/images/createChallenge/background-title.png';
import backgroundTitleLarge from '../../assets/images/bg-header-404.png';
import { useTranslation } from 'react-i18next';

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  max-height: 6.069vw;
`;

const ButtonGoBack = styled('div')`
  display: flex;
  position: relative;
  width: 16.276041666666668vw;
  margin-left: 1.3020833333333333vw;
  display: flex;
  align-items: center;
  margin-top: 2vw;

  & > img {
    position: absolute;
    width: 100%;
  }

  & .bg {
    height: 3vw;
  }

  & img ~ img {
    width: 2vw;
    left: 3vw;
  }

  & > span {
    position: absolute;
    font-size: 1.3020833333333333vw;
    font-weight: bold;
    color: #feffff;
    left: 8.7vw;
    text-transform: uppercase;
  }
`;

const BoxTitle = styled('div')`
  position: relative;
  width: 33vw;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    position: absolute;
    top: -3vw;
    max-width: 20.471vw;
  }
`;
const DefaultTitle = styled('span')`
  position: relative;
  color: white;
  font-size: 1.7vw;
  text-transform: uppercase;
  font-weight: bold;
`;

const WrapContainer = (props) =>
  css({
    height: props.height,
  });

export default function TitleHasLogo({ renderContent, size = 'normal', onBackAction }) {
  const { t } = useTranslation(['common']);

  return (
    <Container className={WrapContainer({ height: size === 'large' ? '3vw' : '5vw' })}>
      <ButtonGoBack onClick={onBackAction}>
        <img className="bg" alt="back-background" src={goBackButton} />
        <img alt="back-icon" src={backIcon} />
        <span>{t('back')}</span>
      </ButtonGoBack>
      <BoxTitle>
        <img alt="back" src={size === 'large' ? backgroundTitleLarge : backgroundTitle} />
        {renderContent && renderContent()}
      </BoxTitle>
      <ButtonGoBack />
    </Container>
  );
}
