import { css, styled } from 'goober';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateChallengeButton from '../../../CreateChallenge/components/CreateChallengeButton';
import bgButton from '../../../../assets/images/createChallenge/bg-button-sharelink.png';
import buttonBgRed from '../../../../assets/images/createChallenge/bg-button-sharelink-hover.png';
import iconZalo from '../../../../assets/images/createChallenge/ic-zalo.png';
import iconFace from '../../../../assets/images/createChallenge/ic-face.png';
import bgCopy from '../../../../assets/images/bg_copy.png';
import ReactTooltip from 'react-tooltip';
import useShareZalo from '../../../../utils/useShareZalo';
import joinChallenge from '../../../../../assets/animation/join-challenge.json';
import clsx from 'clsx';

const spinePlayerConfig = {
  showControls: false,
  alpha: true,
  backgroundColor: '#00000000',
  animation: 'animation',
  viewport: {
    debugRender: false,
    x: 0,
    y: 0,
    padLeft: '3px',
    padRight: '3px',
    padTop: '3px',
    padBottom: '3px',
  },
};

const BoxInviteMemberContainer = styled('div')`
  position: relative;
  z-index: 1;
  width: 35vw;
  height: 35vw;
  z-index: 22;
  & > img {
    position: absolute;
    top: -6vw;
    left: -1vw;
    width: 100%;
    height: 100%;
  }
`;
const Title = styled('span')`
  position: absolute;
  font-size: 3vw;
  text-transform: uppercase;
  font-weight: bold;
  top: 0;

  text-transform: uppercase;
  line-height: 1.2;
  -moz-transform: matrix(0.85401635047899, 0, 0, 0.87270941309071, 0, 0);
  -webkit-transform: matrix(0.85401635047899, 0, 0, 0.87270941309071, 0, 0);
  -ms-transform: matrix(0.85401635047899, 0, 0, 0.87270941309071, 0, 0);

  background: linear-gradient(to top, #ffe5c1, #fff), linear-gradient(to bottom, #00f6ff, #00f6ff);
  -webkit-background-clip: text;
  color: rgb(255 241 220);

  text-shadow: rgb(238 126 109) 2px 0px 0px, rgb(238 126 109) 1.75517px 0.958851px 0px,
    rgb(238 126 109) 1.0806px 1.68294px 0px, rgb(238 126 109) 0.141474px 1.99499px 0px,
    rgb(238 126 109) -0.832294px 1.81859px 0px, rgb(238 126 109) -1.60229px 1.19694px 0px,
    rgb(238 126 109) -1.97998px 0.28224px 0px, rgb(238 126 109) -1.87291px -0.701566px 0px,
    rgb(238 126 109) -1.30729px -1.5136px 0px, rgb(238 126 109) -0.421592px -1.95506px 0px,
    rgb(238 126 109) 0.567324px -1.91785px 0px, rgb(238 126 109) 1.41734px -1.41108px 0px,
    rgb(238 126 109) 1.92034px -0.558831px 0px;
`;

const WrapText = styled('div')`
  position: absolute;
  font-size: 0.75vw;
  color: #ffffff;
  text-align: center;
  .wrapLink-inner {
    z-index: 2;
    position: relative;
  }
  & > p {
    margin: 0;
    z-index: 3;
  }
  .box-copy {
    position: absolute;
    right: 3vw;
    top: 0.25vw;
    z-index: 1;
    width: 6.341vw;
    height: 1.812vw;
    z-index: 2;
    background: url(${iconZalo}) 0/100% no-repeat;
    span {
      z-index: 2;
    }
    .ic-copy {
      position: absolute;
      width: 6.341vw;
      height: 1.812vw;
      z-index: 1;
    }
  }
`;

const WrapLinkText = styled('div')`
  position: absolute;
  font-size: 0.75vw;
  color: #ffffff;
  text-align: center;
  top: 9.5vw;
  left: 6.5vw;
  width: 21vw;

  font-weight: bold;
  .wrapLink-inner {
    z-index: 2;
    position: relative;
    &::-webkit-scrollbar {
      height: 0.5vw;
    }
    &::-webkit-scrollbar-thumb {
      background: #00f6ff;
      border-radius: 5px;
      height: 1vw;
      width: 1vw;
    }
    &::-webkit-scrollbar-track {
      box-shadow: none;
    }
  }

  p {
    z-index: 3;
    span {
      padding-right: 5vw;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 20vw;
      display: block;
    }
  }
  .box-copy {
    position: absolute;
    right: -1vw;
    top: 0vw;
    z-index: 1;
    width: 6.341vw;
    height: 1.812vw;
    z-index: 2;
    span {
      z-index: 2;
    }
    .ic-copy {
      position: absolute;
      width: 6.341vw;
      height: 1.812vw;
      right: -1vw;
      top: 0vw;
      z-index: 1;
    }
  }
`;

const TextContainer = (props) =>
  css({
    top: props.top,
    fontWeight: props.fw,
    width: props.width || '20vw',
    left: props.left || '6.5vw',
    whiteSpace: 'nowrap',
  });
const ButtonStart = styled('div')`
  position: absolute;
  top: 21vw;
  left: 13.5vw;
  button[disabled] {
    opacity: 0.8;
    img {
      filter: grayscale(1);
    }
  }
`;
const BoxCopyTop = styled('div')`
  position: absolute;
  right: 0.25vw;
  top: -0.25vw;
  z-index: 1;
  width: 6.341vw;
  height: 1.812vw;
  padding: 4px 0 0 30px;
  background: url(${bgCopy}) 0/100% no-repeat;
  span {
    z-index: 2;
    top: 0.35vw;
    left: 2.6vw;
    position: absolute;
  }
  .ic-copy {
    position: absolute;
    width: 6.341vw;
    height: 1.812vw;
    right: -0.5vw;
    top: 0;
    z-index: -1;
  }
`;
const BoxCopy = styled('div')`
  position: absolute;
  right: -1.15vw;
  top: 0.25vw;
  width: 6.341vw;
  height: 1.812vw;
  z-index: 2;
  padding: 4px 0 0 30px;
  background: url(${bgCopy}) 0/100% no-repeat;
  span {
    z-index: 2;
    top: 0.35vw;
    left: 2.6vw;
    position: absolute;
  }
  .ic-copy {
    position: absolute;
    width: 6.341vw;
    height: 1.812vw;
    right: -0.5vw;
    top: 0vw;
    z-index: -1;
  }
`;
const ButtonShareWrap = styled('div')`
  position: absolute;
  top: 17.67vw;
  left: 0vw;
  width: 35vw;
  height: 2.264vw;
  display: flex;
  justify-content: center;
`;
const ButtonShareLink = styled('button')`
  width: 10.462vw;
  height: 2.264vw;
  margin: 0 0.5vw;
  border: none;
  background: url(${bgButton}) 0/100% no-repeat;
  transition: 0.3s;
  &:hover {
    background: url(${buttonBgRed}) 0/100% no-repeat;
  }

  img {
    width: 1.178vw;
    padding-right: 0.5vw;
  }
  & span {
    position: relative;
    color: #ffffff;
    font-weight: bold;
    font-size: 0.7vw;
  }
`;

const AnimationJoinChallenger = styled('div')((props) => ({
  position: 'absolute',
  // top: '-6vw',
  // left: '-1vw',
  // width: '100%',
  // height: '100%',
  top: '-13vw',
  left: '-5vw',
  width: '124%',
  height: '125%',
}));

export default function BoxInviteMember({
  onSubmit,
  onShareDirectLink,
  code,
  isDisabled,
  isShowHover,
}) {
  const { t, i18n } = useTranslation(['create_challenge']);
  const url = document.referrer + 'exam' + window.location.pathname;
  const linkRef = React.createRef();
  const codeRef = React.createRef();

  const onCopyLink = () => {
    // navigator.clipboard.writeText(url);
    if (window.location === window.parent.location) {
      navigator.clipboard.writeText(url);
    } else {
      const newUrl = document.referrer + 'exam' + window.location.pathname;
      window.top.postMessage(`COPY_URL|${newUrl}`, '*');
    }
    if (linkRef != null && linkRef.current) {
      ReactTooltip.show(linkRef.current);
    }
  };

  const onCopyCode = () => {
    navigator.clipboard.writeText(code);
    window.top.postMessage(
      {
        key: 'ON_COPY',
        value: code,
      },
      '*',
    );
    //ON_COPY
    if (codeRef != null && codeRef.current) {
      ReactTooltip.show(codeRef.current);
    }
  };
  const attributes = useShareZalo({ href: url });

  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    new spine.SpinePlayer('join-challenge', {
      ...joinChallenge,
      ...spinePlayerConfig,
    });
  }, []);

  return (
    <BoxInviteMemberContainer>
      {/*<img src={bgInviteMember} alt="invite-member" />*/}
      <AnimationJoinChallenger id="join-challenge"></AnimationJoinChallenger>
      <Title
        className={css({
          left: i18n.language === 'en' ? '5vw' : '7vw',
        })}
      >
        {t('invite_friend')}
      </Title>
      <WrapText
        className={TextContainer({
          top: '7vw',
          overflow: 'hidden',
        })}
      >
        <span>{t('label_open_link')}</span>
      </WrapText>
      <WrapLinkText>
        <div className="wrapLink-inner">
          <span
            ref={linkRef}
            data-tip={t('link_copied')}
            data-delay-hide="1000"
            data-class={{
              fontSize: '1.2vw',
            }}
            className={css`
              position: absolute;
              right: 4vw;
              top: 0vw;
            `}
          />
          <p className={css({ whiteSpace: 'nowrap', margin: 0 })} onClick={onCopyLink}>
            <span>{url}</span>
          </p>
        </div>
        <BoxCopyTop>
          <span>{t('copied')}</span>
          <img className="ic-copy" alt="icon copy link" src={bgCopy} />
        </BoxCopyTop>
      </WrapLinkText>
      <WrapText
        className={TextContainer({
          top: '12.5vw',
          left: '5vw',
          width: '20vw',
        })}
      >
        <span>{t('label_challenge_code')}</span>
      </WrapText>
      <WrapText
        className={TextContainer({
          top: '15vw',
          fw: 'bold',
          left: '5vw',
          padding: '5px 0!important',
          width: '23vw',
        })}
      >
        <div className="wrapLink-inner">
          <span
            ref={codeRef}
            data-tip={t('code_copied')}
            data-delay-hide="1000"
            data-class={{
              fontSize: '1.2vw',
            }}
            className={css`
              position: absolute;
              right: 4vw;
              top: 0vw;
            `}
          />
          <p
            className={css({ textTransform: 'uppercase', padding: '0.5vw 0' })}
            onClick={onCopyCode}
          >
            <span>{code}</span>
          </p>
        </div>
        <BoxCopy onClick={onCopyCode}>
          <span>{t('copied')}</span>
          <img className="ic-copy" alt="icon copy link" src={bgCopy} />
        </BoxCopy>
      </WrapText>
      <ButtonShareWrap>
        <ButtonShareLink onClick={() => onShareDirectLink(url)}>
          <div
            className={css({
              position: 'relative',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            })}
          >
            <img src={iconFace} alt="button-share-link" />
            <span>{t('button_share_direct_link1')}</span>
          </div>
        </ButtonShareLink>

        <ButtonShareLink>
          <div
            className={clsx(
              'zalo-share-button',
              css({
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }),
            )}
            {...attributes}
            title={'caption'}
            data-layout="1"
            data-color="blue"
          >
            <img src={iconZalo} alt="button-share-link" />
            <span>{t('button_share_direct_link2')}</span>
          </div>
        </ButtonShareLink>
      </ButtonShareWrap>

      <ButtonStart>
        <CreateChallengeButton
          disabled={isDisabled}
          onClick={onSubmit}
          text={t('start')}
          isShowHover={isShowHover}
          dataHover={t('limit')}
        />
      </ButtonStart>
      <ReactTooltip delayHide={1000} globalEventOff="click" />
    </BoxInviteMemberContainer>
  );
}
