import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { defaultLocale } from '../i18n';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
// import AuthenticatedRoute from './authenticated-route';
import UnauthenticatedRoute from './unauthenticated-route';
// import StagedRoute from './staged-route';
// import CompleteSignup from './complete-signup';
import SpeakingListing from '../components/private/speaking-listing';
// import Report from '../components/private/report';
// import useAuth from '../hooks/use-auth';
import { NotFoundPage } from '../components/public/not-found';
import ProtectedComponent from './protected-component';
import { isMobileOrTablet } from '../utils/helpers/userAgent';

// import { LoginComponent } from '../components/public';
// import { SignUpScreen } from '../v2/screen/Signup';
// import { ForgotPassword } from '../v2/screen/ForgotPassword';
import { UserHasNotExam } from '../v2/screen/UserHasNotExam';

// import TestResultsComponent, {
//   TestResultsOverviewComponent,
// } from '../components/private/test-results';

// import {
//   CheckPoint,
//   ListSpeakingCheckPointComponent,
//   ListVocabularyCheckPointComponent,
//   ResultSummary,
// } from '../components/private';

import SessionsCheckComponent from './sessions-check';
// import CreateChallenge from '../v2/screen/CreateChallenge';
import UserChallenge from '../v2/screen/JoinChallenge/user-challenge';
import ChallengeNoti from '../v2/screen/JoinChallenge/challenge-noti';
// import JoinChallenge from '../v2/screen/JoinChallenge';
import UserLeaderboard from '../v2/screen/LeaderBoardChallenge/user-leaderboard';

function AppRoutes() {
  return (
    <DndProvider backend={!isMobileOrTablet() ? HTML5Backend : TouchBackend}>
      <Switch>
        {/* <StagedRoute exact path="/complete-signup">
          <CompleteSignup />
        </StagedRoute> */}

        {/*<UnauthenticatedRoute exact path={['/test-level-ranking/:id', '/history', '/reward']}>*/}
        {/*  <MainHomeComponent />*/}
        {/*</UnauthenticatedRoute>*/}

        {/*<UnauthenticatedRoute exact path="/test-level/:id">*/}
        {/*  <ListExamCheckPointComponent />*/}
        {/*</UnauthenticatedRoute>*/}

        {/* <UnauthenticatedRoute exact path="/test-level-s/:id">
          <ListSpeakingCheckPointComponent />
        </UnauthenticatedRoute> */}

        {/* <UnauthenticatedRoute exact path="/test-level-v/:id">
          <ListVocabularyCheckPointComponent />
        </UnauthenticatedRoute> */}

        {/* <UnauthenticatedRoute exact path="/check-point/:id">
          <CheckPoint />
        </UnauthenticatedRoute> */}

        {/* <UnauthenticatedRoute exact path="/test-results/:id">
          <TestResultsComponent />
        </UnauthenticatedRoute> */}

        {/* <UnauthenticatedRoute exact path="/test-results/:id/result">
          <ResultSummary />
        </UnauthenticatedRoute> */}

        {/* <UnauthenticatedRoute exact path="/test-results/:id/overview">
          <TestResultsOverviewComponent />
        </UnauthenticatedRoute> */}

        <UnauthenticatedRoute exact path="/speaking-listing/:playlistTestLevelId">
          <SpeakingListing />
        </UnauthenticatedRoute>

        {/* <UnauthenticatedRoute exact path="/login">
          <LoginComponent />
        </UnauthenticatedRoute> */}

        {/* <UnauthenticatedRoute exact path="/signup">
          <SignUpScreen />
        </UnauthenticatedRoute> */}

        {/* <UnauthenticatedRoute exact path="/forgot-password">
          <ForgotPassword />
        </UnauthenticatedRoute> */}

        {/*<UnauthenticatedRoute exact path="/join-a-class/:classCode">*/}
        {/*  <StudentJoinClassByLinkComponent />*/}
        {/*</UnauthenticatedRoute>*/}

        {/* <UnauthenticatedRoute exact path="/auth-verification">
          <AuthVerificationComponent />
        </UnauthenticatedRoute> */}

        {/* <UnauthenticatedRoute exact path="/confirm-verification">
          <ConfirmVerification />
        </UnauthenticatedRoute> */}

        {/* <AuthenticatedRoute path="/report">
          <Report />
        </AuthenticatedRoute> */}

        {/* <UnauthenticatedRoute path="/create-challenge/:id">
          <CreateChallenge />
        </UnauthenticatedRoute> */}

        <UnauthenticatedRoute exact path="/join-challenge/:code">
          <UserChallenge />
        </UnauthenticatedRoute>

        <UnauthenticatedRoute exact path="/leader-board-challenge/:code">
          <UserLeaderboard />
        </UnauthenticatedRoute>

        {/* <UnauthenticatedRoute exact path="/join-challenge/join/:tab">
          <JoinChallenge />
        </UnauthenticatedRoute> */}
{/* 
        <UnauthenticatedRoute exact path="/join-challenge/join/:tab/:code">
          <JoinChallenge />
        </UnauthenticatedRoute> */}

        <UnauthenticatedRoute exact path="/not-found-challenge">
          <ChallengeNoti />
        </UnauthenticatedRoute>

        <UnauthenticatedRoute exact path="/not-found-user-no-exam">
          <UserHasNotExam />
        </UnauthenticatedRoute>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </DndProvider>
  );
}

export default function MainComponent() {
  return (
    <Router>
      <SessionsCheckComponent />
      <ProtectedComponent />
      <Switch>
        <Route path={defaultLocale === 'vi' ? '/en' : '/vi'}>
          <Router basename={defaultLocale === 'vi' ? 'en' : 'vi'}>
            <AppRoutes />
          </Router>
        </Route>
        <Route path="/">
          <Router basename="">
            <AppRoutes />
          </Router>
        </Route>
      </Switch>
    </Router>
  );
}
