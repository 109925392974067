import React, { useEffect, useState } from 'react';
// import firebase from 'firebase/app';
import { ApolloClient, ApolloProvider, createHttpLink, gql, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { useCookie } from 'react-use';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
// import { CREATE_ANONYMOUS_USER } from '../v2/graphql/mutation';
import AuthContext from '../context/auth';
import AUTH_STATE from '../utils/constants/auth-state';
import PageLoader from '../components/public/page-loader';
import MainComponent from './main';
// import { SIGN_OUT } from '../graphql/mutation';
// import ory from '../utils/ory';
import { params } from '../v2/utils/url';
import PageLoaderV2 from '../components/public/page-loader-v2';
// const fbAuth = firebase.auth();

// let tokenPayload;

const wsClient = new SubscriptionClient(process.env.REACT_APP_GRAPHQL_WS, {
  reconnect: true,
  lazy: true,
  connectionParams: getAuthorizationHeader,
  minTimeout: 10000,
});

// function setTokenPayload(tokenResult) {
//   if (tokenResult.token === tokenPayload?.token) return;

//   tokenPayload = {
//     token: tokenResult.token,
//     expirationTime: dayjs(tokenResult.expirationTime),
//   };

//   if (wsClient.status === 1) {
//     wsClient.close(false, true);
//   }
// }

// fbAuth.onIdTokenChanged(async (user) => {
//   if (user) {
//     setTokenPayload(await user.getIdTokenResult());
//   } else {
//     if (wsClient.status === 1) {
//       wsClient.close(true, true);
//     }
//     tokenPayload = undefined;
//   }
// });

async function getAuthorizationHeader() {
  // let token;
  // if (fbAuth.currentUser) {
  //   if (!tokenPayload || tokenPayload.expirationTime < dayjs()) {
  //     setTokenPayload(await fbAuth.currentUser.getIdTokenResult());
  //   }
  //   token = tokenPayload.token;
  // }

  const newHeaders = {};
  // if (token) {
  //   newHeaders.Authorization = `Bearer ${token}`;
  // }
  const profileId = Cookies.get(process.env.REACT_APP_COOKIE_PROFILE_ID || 'profile_id');
  if (profileId) {
    newHeaders['x-profile-id'] = profileId;
  }
  return {
    headers: newHeaders,
  };
}

const authLink = setContext(getAuthorizationHeader);

const httpLinkV1 = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
  credentials: 'include',
});
const httpLinkV2 = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API_V2,
  credentials: 'include',
});
const httpLink = split(
  ({ getContext }) => {
    const context = getContext();
    return context.v2;
  },
  httpLinkV2,
  httpLinkV1,
);

const wsLink = new WebSocketLink(wsClient);

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache({
    typePolicies: {
      ClassStudent: {
        keyFields: ['joinedDate'],
      },
    },
  }),
});

function getViewerV2() {
  return client.query({
    query: gql`
      query GetViewerQuery(
        $after: Cursor
        $first: Int
        $last: Int
        $before: Cursor
        $orderBy: LearnerProfileOrder
      ) {
        viewer {
          id: userId
          email
          emailVerified
          phoneNumber
          subscriptionExpiresAt
          isSubscribing
          learnerProfiles(
            after: $after
            first: $first
            before: $before
            last: $last
            orderBy: $orderBy
          ) {
            nodes {
              id: learnerProfileId
              firstName
              lastName
              displayName
              dateOfBirth
              avatarUrl
              gender
              school {
                id: schoolId
                name
                province {
                  id: provinceId
                  name
                }
              }
            }
          }
          teacherProfile {
            id: teacherProfileId
            firstName
            lastName
            displayName
            dateOfBirth
            avatarUrl
            gender
            about
          }
        }
      }
    `,
    variables: {
      first: 50,
    },
    context: {
      v2: true,
    },
  });
}

const domain = window.location.hostname.split('.').reverse().splice(0, 2).reverse().join('.');

export default function Auth() {
  const [auth, setAuth] = React.useState({ status: AUTH_STATE.LOADING });
  const [isErrorNetWork, setIsErrorNerWork] = useState(false);
  // const [anonymousProfileId, updateAnonymousCookie] = useCookie(
  //   process.env.REACT_APP_COOKIE_ANONYMOUS_PROFILE_ID || 'anonymous_profile_id',
  // );

  const getUserInfo = React.useCallback(async () => {
    getViewerV2()
      .then(({ data }) => {
        if (data?.viewer) {
          setAuth({ status: AUTH_STATE.LOADING });
          if (data.viewer.learnerProfiles?.length === 0) {
            setAuth({
              status: AUTH_STATE.STAGED,
              // authTime,
              user: {
                ...data.viewer,
                id: data.viewer.id.toString(),
                profiles: data.viewer.learnerProfiles.nodes.map((p) => {
                  return {
                    ...p,
                    id: p.id.toString(),
                  };
                }),
                subscriptionExpiredAt: data.viewer.subscriptionExpiresAt,
              },
            });
          } else {
            setAuth({
              status: AUTH_STATE.IN,
              // authTime,
              user: {
                ...data.viewer,
                id: data.viewer.id.toString(),
                subscriptionExpiredAt: data.viewer.subscriptionExpiresAt,
                profiles: data.viewer.learnerProfiles.nodes.map((p) => {
                  return {
                    ...p,
                    id: p.id.toString(),
                  };
                }),
              },
            });
          }
        } else {
          // const getAnonymousProfile = async () => {
          //   if (!anonymousProfileId) {
          //     const data = await client.mutate({
          //       mutation: CREATE_ANONYMOUS_USER,
          //       variables: {
          //         object: {
          //           display_name: 'Flyer member',
          //         },
          //       },
          //     });
          //     if (data.data?.insert_profiles_one?.id) {
          //       updateAnonymousCookie(data.data?.insert_profiles_one?.id, {
          //         domain,
          //         sameSite: 'none',
          //         secure: true,
          //       });
          //     }
          //   }
          //   setAuth({
          //     status: AUTH_STATE.OUT,
          //   });
          // };
          // getAnonymousProfile();
          setAuth({
            status: AUTH_STATE.OUT,
          });
        }
      })
      .catch(console.error);
  }, []);

  const reloadUser = React.useCallback(async () => {
    getUserInfo();
  }, [getUserInfo]);

  React.useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  const [profileId, updateCookie] = useCookie(
    process.env.REACT_APP_COOKIE_PROFILE_ID || 'profile_id',
  );

  const currentProfile = React.useMemo(() => {
    if (!auth.user) {
      return {
        // id: anonymousProfileId,
        isAnonymousUser: true,
        display_name: 'Flyer member',
      };
    }
    const { profiles } = auth.user;
    return (
      profiles.find((profile) => profileId === profile.id) ||
      profiles.find((profile) => profile.isRoot) ||
      profiles[0]
    );
  }, [auth.user, profileId]);

  const isCheckSession = React.useRef(true);

  const value = React.useMemo(
    () => ({
      ...auth,
      currentProfile,
      changeProfile: (value) => {
        updateCookie(value, {
          domain,
          sameSite: 'none',
          secure: true,
        });
      },
      reloadUser,
      isCheckSession,
    }),
    [auth, currentProfile, reloadUser, updateCookie],
  );

  useEffect(() => {
    if (params.url_back) {
      localStorage.setItem('url_back', params.url_back);
    }
  }, []);
  useEffect(() => {
    const checkNetWorkError = () => {
      setIsErrorNerWork(true);
    };
    window.addEventListener('offline', checkNetWorkError);
    return () => window.removeEventListener('offline', checkNetWorkError);
  }, []);

  useEffect(() => {
    const checkNetWorkOnline = () => {
      setIsErrorNerWork(false);
    };
    window.addEventListener('online', checkNetWorkOnline);
    return () => window.removeEventListener('online', checkNetWorkOnline);
  }, []);
  return (
    <AuthContext.Provider value={value}>
      <ApolloProvider client={client}>
        {isErrorNetWork && <PageLoaderV2 />}
        {auth.status === AUTH_STATE.LOADING ? <PageLoader /> : <MainComponent />}
      </ApolloProvider>
    </AuthContext.Provider>
  );
}
