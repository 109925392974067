import { useMutation, useQuery, useSubscription } from '@apollo/client';
import dayjs from 'dayjs';
import { css, styled } from 'goober';
import { get, groupBy, keyBy } from 'lodash';
import parseInterval from 'postgres-interval';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import PageLoader from '../../../components/public/page-loader';
import BgUserListLeftChallenge from '../../assets/images/bg-user-left.png';
import BgUserListRightChallenge from '../../assets/images/bg-user-right.png';
import backgroundPage from '../../assets/images/createChallenge/backgroundPage.png';
import IconUserChallenge from '../../assets/images/ic-user-challenge.png';
import Header from '../../components/Header';
import {
  CHECK_CHALLENGE_EXTENDS,
  COUNT_SUBMIT,
  GET_CHALLENGE_BY_CODE,
  GET_CHALLENGE_PLAYERS,
  GET_CHECK_POINTS_EXAM_STRUCTURES,
  GET_THEME_BACKGROUND_FOR_WEB,
} from '../../graphql/query';
import { END_SESSION_CHALLENGE, PLAYER_JOIN_CHALLENGE } from '../../graphql/mutation';
import { onShareFB } from '../../utils/helper';
import ChallengeNoti from './challenge-noti';
import UserInfo from './components/AccountInfo';
import BoxInviteMember from './components/BoxInviteMember';
import ChallengeInfo from './components/ChallengeInfo';
import useLearnerProfile from '../../hook/useLearnerProfile';
import Countdown from '../../components/Countdown';
import { Howl } from 'howler';
import CountdownTop from 'react-countdown';
import bgCountdownTop from '../../assets/images/bg-countdown-top.png';
import Meta from '../../components/Meta';
import UpdateGradeAccountPage from '../../components/UpdateGradeAccountPage';
import useAuth from '../../../hooks/use-auth';
import { useAtom } from 'jotai';
import { handlerOnOffSoundAtom, listThemeOptionAtom, themeSelectEdAtom } from '../../hook/common';
import { SUBSCRIPTION_CHALLENGE } from '../../graphql/subscription';
import EndChallenge from './components/EndChallenge/endChallenge';
import useThemes from '../../hook/useThemes';
import { joinChallengeAtom } from '../../store/jotai';
import { useCookie } from 'react-use';

const Container = styled('div')`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  font-family: 'OpenSans';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .absolute {
    position: absolute;
  }
`;

const Body = styled('div')`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ListUser = styled('div')`
  position: relative;
  padding-top: 1vw;
  // &:before {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   content: '';
  //   height: 0.136vw;
  //   width: 100%;
  //   background: rgb(79, 172, 254);
  //   background: linear-gradient(
  //     90deg,
  //     rgba(79, 172, 254, 0.08169205182072825) 0%,
  //     rgba(0, 242, 254, 0.4710477941176471) 49%,
  //     rgba(79, 172, 254, 0.048078606442577) 100%
  //   );
  // }
`;
const BoxListUserChallenge = styled('div')`
  /* position: relative; for Version 2 */
  position: absolute;
  /* width: 20.833vw; for version 2 */
  width: 100%;
  height: 27vw;
  border-radius: 0.589vw;

  border: solid 1px #00f6ff;
  background-color: #2a226d;
  padding: 0.861vw 1.26vw;
  display: flex;
  justify-content: flex-end;
  z-index: 1;

  &:before {
    position: absolute;
    top: -1vw;
    bottom: -1vw;
    left: -1.1vw;
    content: '';
    width: 3.261vw;
    background: url(${BgUserListLeftChallenge}) no-repeat 0/100% !important;
    background-size: 100% 100% !important;
  }
  &:after {
    position: absolute;
    top: -1vw;
    bottom: -1vw;
    right: -1.1vw;
    content: '';
    width: 3.261vw;
    background: url(${BgUserListRightChallenge}) no-repeat 0/100% !important;
    background-size: 100% 100% !important;
  }
`;
const BoxListUserChallengeInner = styled('div')`
  max-height: 28vw;
  overflow-y: auto;
  padding: 1vw 1vw 0 1vw;
  max-width: 18.2vw;
`;
const NumberUserChallenge = styled('div')`
  position: relative;
  text-align: center;
  padding: 0.589vw 0.898vw;
  object-fit: contain;
  border-radius: 13px;
  background-color: #140e42;
  font-size: 0.725vw;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin-bottom: 1vw;
`;
const IconUserChallengeImg = css`
  width: 1.087vw;
  padding-right: 0.25vw;
`;
const ScrollbarStyle = css`
  &::-webkit-scrollbar {
    width: 0.317vw !important;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #00fffc !important;
  }
`;
const WrapChallenge = styled('div')`
  width: 80vw;
  height: 30vw;
  position: relative;
  display: flex;
  margin-top: 5.5vw;
`;
const TitleHeader = styled('span')`
  position: relative;
  color: #00ffc6;
  font-size: 1vw;
  font-weight: bold;
  text-align: center;
  padding: 0 1.65vw;
`;
const CountdownBox = css`
  display: flex;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 0vw;
  .box-countdown {
    background: url(${bgCountdownTop}) no-repeat 0/100%;
    width: 22.92vw;
    height: 5.072vw;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'OpenSans';
    color: #fff;
    .box-item {
      padding: 0 0.25vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span.top {
        color: #00f6ff;
        font-size: 2.57vw;
        font-weight: bold;
        line-height: 2.9vw;
      }
      span {
        font-size: 0.453vw;
      }
    }
  }
`;

const ANONYMOUS_DISPLAY_NAME = 'Flyer member';

export default function UserChallenge() {
  const { t } = useTranslation(['join_challenge', 'common']);
  const history = useHistory();
  const [anonymousLearnerProfileId] = useCookie(
    process.env.REACT_APP_COOKIE_ANONYMOUS_LEARNER_PROFILE_ID || 'anonymous_learner_profile_id',
  );

  // const joinChallenge = useStore((s) => s.joinChallenge);
  const [showUpgradeAccount, setShowUpgradeAccount] = useState(false);
  const [handlerOnOffSound, setHandlerOnOffSound] = useAtom(handlerOnOffSoundAtom);

  const [showChangeTheme, setShowChangeTheme] = useState(false);
  const { user, currentProfile } = useAuth();
  const [themeSelectEd, setThemeSelectEd] = useAtom(themeSelectEdAtom);
  const [, setJoinChallenge] = useAtom(joinChallengeAtom);
  const [showCountdownTop, setShowCountdownTop] = useState(false);
  const [showEndPopupSession, setShowEndPopupSession] = useState(false);
  const [showHideButtonViewDashboard, setShowHideButtonViewDashboard] = useState(false);
  const [listThemeOption] = useAtom(listThemeOptionAtom);
  const [messageError, setMessageError] = useState({
    messageError: 'create_challenge_title',
  });
  const [showChallengeNotificationInfo, setShowChallengeNotificationInfo] = useState({
    show: false,
  });

  const sound = React.useMemo(
    () =>
      new Howl({
        src: '/waiting-room.mp3',
        loop: true,
      }),
    [],
  );

  const [learnerProfile, , learnerProfileLoading] = useLearnerProfile();

  const { getValues, setValue, handleSubmit, watch } = useForm({
    defaultValues: {
      userName: learnerProfile?.displayName,
    },
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });
  const userName = watch('userName');
  const { i18n } = useTranslation(['common', 'create_challenge']);

  const { code } = useParams();

  const { data: challengeInfo, loading: challengeInfoLoading } = useQuery(GET_CHALLENGE_BY_CODE, {
    variables: { code },
    fetchPolicy: 'network-only',
  });
  // check limit
  const { data: checkLimitChallenge } = useQuery(CHECK_CHALLENGE_EXTENDS, {
    variables: {
      where: {
        code: {
          _eq: code,
        },
      },
    },
  });

  const isLimit =
    checkLimitChallenge?.challenges?.length > 0
      ? checkLimitChallenge?.challenges[0]?.is_limit
      : false;

  const isHidden =
    checkLimitChallenge?.challenges?.length > 0
      ? checkLimitChallenge?.challenges[0]?.is_hidden_name
      : false;
  // check has complete once
  const { data: dataCountSubmit } = useSubscription(COUNT_SUBMIT, {
    variables: {
      where: {
        learner_profile_id: {
          _eq: currentProfile?.isAnonymousUser ? anonymousLearnerProfileId : currentProfile?.id,
        },
        challenge_id: {
          _eq: challengeInfo?.challenges[0]?.id,
        },
        submitted_at: {
          _is_null: false,
        },
      },
    },
    skip: !challengeInfo?.challenges[0]?.id,
  });
  const isNotPermissionPlayChallenge = isLimit && !!dataCountSubmit?.userAttempts?.aggregate?.count;

  const [playerJoinChallenge] = useMutation(PLAYER_JOIN_CHALLENGE, {
    variables: {
      challengeId: challengeInfo?.challenges[0]?.id,
      learnerProfileId: learnerProfile?.id,
    },
  });
  const [onSubmitEndChallenge] = useMutation(END_SESSION_CHALLENGE, {
    variables: {
      id: challengeInfo?.challenges[0]?.id,
      input: {
        end_at: dayjs().format(),
      },
    },
  });
  // TODO: use infinity scroll
  const { data: challengePlayersData } = useQuery(GET_CHALLENGE_PLAYERS, {
    variables: {
      where: { challenge_id: { _eq: challengeInfo?.challenges[0]?.id } },
      order: {
        joined_at: 'asc',
      },
      limit: 100,
      offset: 0,
    },
    skip: !challengeInfo?.challenges[0]?.id,
    pollInterval: 2000,
  });
  const playersCount = challengePlayersData?.challengeRankingAggregate.aggregate.count || 0;

  // get list player and total player joined challenge
  const challengePlayers = challengePlayersData?.challengeRankingPlayers.map(
    ({ learnerProfile }) => learnerProfile,
  );

  const {
    data: checkPointData = {
      checkPoint: { testLevel: { examSettings: [] }, cpQuestions: [] },
      examStructures: [],
    },
    loading: checkPointDataLoading,
  } = useQuery(GET_CHECK_POINTS_EXAM_STRUCTURES, {
    skip: !challengeInfo?.challenges[0]?.checkPoint?.id,
    variables: { id: challengeInfo?.challenges[0]?.checkPoint?.id },
  });
  // subcriptsion check session end;
  const { data: dataBackGround, loading: loadingGetTheme } = useQuery(
    GET_THEME_BACKGROUND_FOR_WEB,
    {
      variables: {
        where: {
          type: {
            _eq: 'challenge',
          },
        },
      },
    },
  );
  const [, setListThemeOption] = useAtom(listThemeOptionAtom);

  const { listThemesFinal } = useThemes(dataBackGround || []);
  useEffect(() => {
    if (dataBackGround === undefined || dataBackGround === null) return;
    setListThemeOption(listThemesFinal);
    if (
      themeSelectEd === undefined ||
      themeSelectEd === null ||
      themeSelectEd === 0 ||
      typeof themeSelectEd === 'string'
    ) {
      setThemeSelectEd(100);
    }
  }, [dataBackGround]);
  const optionThemeSelectEd = React.useMemo(() => {
    return listThemeOption.find((item) => item.id === themeSelectEd);
  }, [themeSelectEd]);

  const handlerClickViewDashboard = () => {
    // let lang = i18n.language || '';
    // if (
    //   (i18n.language === 'en' && window.location.hostname.includes('.us')) ||
    //   (i18n.language === 'vi' && window.location.hostname.includes('.vn')) ||
    //   (i18n.language === 'vi' && window.location.hostname.includes('localhost'))
    // ) {
    //   lang = '';
    // }
    // return (window.location.href =
    //   lang.trim().length > 0
    //     ? `/${lang}/leader-board-challenge/${code}`
    window.top.postMessage({ key: 'VIEW_LEADER_BOARD', value: code }, '*');
  };

  const isShowEndSession = useMemo(() => {
    //endAt
    if (currentProfile?.isAnonymousUser) return false;
    const userCreateChallenge = challengeInfo?.challenges[0]?.learnerProfile?.id.toString();
    const currentUserId = currentProfile.id.toString();
    if (showCountdownTop) {
      return false;
    }
    return userCreateChallenge === currentUserId;
  }, [currentProfile, challengeInfo, showCountdownTop]);

  const renderTitle = React.useCallback(() => {
    return <TitleHeader>{t(messageError.messageError)}</TitleHeader>;
  }, [messageError, t]);

  const handleShareDirectLink = (link) => {
    onShareFB(link);
  };

  const questionsByExam = React.useMemo(
    () =>
      groupBy(
        checkPointData?.checkPoint?.cpQuestions?.map(({ question }) => question),
        'examType',
      ),
    [checkPointData?.checkPoint?.cpQuestions],
  );

  const examStructures = React.useMemo(() => {
    const examSettingsKeyByExamType = keyBy(
      checkPointData?.checkPoint?.testLevel?.examSettings,
      'examType',
    );
    return checkPointData?.examStructures
      .filter(({ examType }) => questionsByExam[examType]?.length)
      .map((examStructure) => {
        return {
          ...examStructure,
          executionTime: parseInterval(
            examSettingsKeyByExamType[examStructure.examType].executionTime,
          ).minutes,
        };
      });
  }, [
    checkPointData?.checkPoint?.testLevel.examSettings,
    checkPointData?.examStructures,
    questionsByExam,
  ]);

  const onBackAction = () => {
    // history.push(`/create-challenge/${challengeInfo?.challengeByCode?.checkPoint?.id}`);
    //CREATE_CHALLENGE
    if (isNotPermissionPlayChallenge) {
      return window.top.postMessage('HOME', '*');
    }
    window.top.postMessage('GO_BACK_OR_HOME', '*');
  };
  const countdownRef = React.useRef(null);

  const handleStartChallenge = React.useCallback(async () => {
    const value = getValues('userName');
    const challengeId = challengeInfo?.challenges[0]?.id;
    const challengeCode = challengeInfo?.challenges[0]?.code;
    const challengeName = challengeInfo?.challenges[0]?.name;

    // const isPremium = challengeInfo?.challengeByCode?.checkPoint?.checkPoint?.is_premium;
    //
    // if (isPremium) {
    //   if (!user || !user?.isSubscribing) {
    //     setShowUpgradeAccount(true);
    //     return;
    //   }
    // }

    setJoinChallenge({
      playerName: value,
      challengeId,
      settings: challengeInfo?.challenges[0]?.settings,
      challengeEnd: challengeInfo?.challenges[0]?.endAt,
      challengeCode,
      challengeName: challengeName,
    });
    countdownRef.current.startCountdown();
  }, [
    challengeInfo?.challenges[0]?.checkPoint?.checkPoint?.is_premium,
    challengeInfo?.challenges[0]?.code,
    challengeInfo?.challenges[0]?.endAt,
    challengeInfo?.challenges[0]?.id,
    challengeInfo?.challenges[0]?.name,
    challengeInfo?.challenges[0]?.settings,
    getValues,
    setJoinChallenge,
    user,
  ]);

  const onCountdownFinished = React.useCallback(() => {
    const challengeCode = challengeInfo?.challenges[0]?.code;

    // history.push(
    //     `/check-point/${challengeInfo?.challengeByCode?.checkPoint?.id}?challengeCode=${challengeCode}`,
    window.top.postMessage(
      {
        key: 'GO_TO_CHECKPOINT',
        value: `${challengeInfo?.challenges[0]?.checkPoint?.id}?challengeCode=${challengeCode}`,
      },
      '*',
    );
    // window.top.postMessage(
    //   {
    //     key: 'GO_TO_CHECKPOINT',
    //     value: `${challengeInfo?.challengeByCode?.checkPoint?.id}?challengeCode=${challengeCode}`,
    //   },
    //   '*',
    // );
  }, [challengeInfo?.challenges[0]?.checkPoint?.id, challengeInfo?.challenges[0]?.code]);

  const onCompleteCountdown = useCallback(() => {
    setShowCountdownTop(false);
    setMessageError({ messageError: 'create_challenge_title' });
  }, []);
  useEffect(() => {
    setHandlerOnOffSound(challengeInfo?.challenges[0]?.settings?.sound);
  }, [challengeInfo]);
  useEffect(() => {
    if (!countdownRef.current?.showOverlay && handlerOnOffSound) {
      sound.play();
    } else {
      sound.stop();
    }
  }, [challengeInfo, sound, handlerOnOffSound]);

  useEffect(() => {
    return () => sound.stop();
  }, [sound]);

  useEffect(() => {
    if (countdownRef.current?.showOverlay) {
      sound.stop();
    }
  }, [countdownRef.current?.showOverlay, sound]);

  useEffect(() => {
    if (
      dayjs(challengeInfo?.challenges[0]?.startAt).valueOf() - dayjs(Date.now()).valueOf() >
      0
    ) {
      setShowCountdownTop(true);
    }
  }, [challengeInfo?.challenges[0]?.startAt]);

  useEffect(() => {
    if (challengeInfoLoading) {
      return;
    }

    if (!challengeInfo?.challenges[0]) {
      setShowChallengeNotificationInfo({
        show: true,
        message: 'error_challenge_not_found',
      });
      return;
    }
    const { startAt, endAt } = challengeInfo.challenges[0];
    if (startAt && dayjs().isBefore(dayjs(startAt))) {
      setMessageError({ messageError: 'error_challenge_start_time' });
      setShowHideButtonViewDashboard(false);
      return;
    }

    if (endAt && dayjs().isAfter(dayjs(endAt))) {
      setShowChallengeNotificationInfo({ show: true, message: 'error_challenge_end_time' });
      setShowHideButtonViewDashboard(true);
      return;
    }
  }, [challengeInfo, challengeInfoLoading]);

  useEffect(() => {
    if (learnerProfile?.displayName) {
      setValue('userName', learnerProfile?.displayName);
    }
  }, [learnerProfile?.displayName, setValue]);

  useEffect(() => {
    // when challenge end return
    if (!challengeInfo?.challenges[0]) {
      return;
    }
    const { endAt } = challengeInfo?.challenges[0];

    if (endAt && dayjs().isAfter(dayjs(endAt))) {
      setShowChallengeNotificationInfo({ show: true, message: 'error_challenge_end_time' });
      setShowHideButtonViewDashboard(true);
      return;
    }
    if (!learnerProfile?.id && !learnerProfileLoading) {
      // history.push(`/join-challenge/join/join-by-code/${code}`);
      window.top.postMessage({ key: 'JOIN_BY_CODE_ANONYMUST', value: code }, '*');
    }
  }, [history, code, learnerProfile?.id, learnerProfileLoading, challengeInfo]);

  useEffect(() => {
    // when challenge end return
    if (!challengeInfo) {
      return;
    }
    const { endAt } = challengeInfo?.challenges[0];
    if (endAt && dayjs().isAfter(dayjs(endAt))) {
      setShowChallengeNotificationInfo({ show: true, message: 'error_challenge_end_time' });
      setShowHideButtonViewDashboard(true);
      return;
    }
    if (challengeInfo?.challenges[0]?.id && learnerProfile?.id) {
      playerJoinChallenge();
    }
  }, [challengeInfo?.challenges[0]?.id, learnerProfile?.id, playerJoinChallenge]);

  useEffect(() => {
    const endAt = challengeInfo?.challenges[0]?.endAt;
    if (!endAt) return;

    if (dayjs().isAfter(dayjs(endAt))) {
      setShowChallengeNotificationInfo({ show: true, message: 'error_challenge_end_time' });
      setShowHideButtonViewDashboard(true);
      return;
    }

    const timeMillisecond = dayjs(dayjs(endAt)).diff(dayjs(), 'millisecond');
    const timer = setTimeout(() => {
      setShowChallengeNotificationInfo({ show: true, message: 'error_challenge_end_time' });
      setShowHideButtonViewDashboard(true);
    }, timeMillisecond);
    return () => clearTimeout(timer);
  }, [challengeInfo?.challenges[0]?.endAt]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return '';
    } else {
      // Render a countdown
      return (
        <div className="box-countdown">
          <div className="box-item">
            <span className="top">{days} : </span>
            <span>{t('day_countdown')}</span>
          </div>
          <div className="box-item">
            <span className="top">{hours} : </span>
            <span>{t('hour_countdown')}</span>
          </div>
          <div className="box-item">
            <span className="top">{minutes} : </span>
            <span>{t('minute_countdown')}</span>
          </div>
          <div className="box-item">
            <span className="top">{seconds}</span>
            <span>{t('second_countdown')}</span>
          </div>
        </div>
      );
    }
  };
  // subscription
  useSubscription(SUBSCRIPTION_CHALLENGE, {
    variables: {
      where: {
        code: {
          _eq: code,
        },
      },
    },
    onSubscriptionData: (res) => {
      const endAt = get(res, 'subscriptionData.data.challenges[0].endAt');
      if (dayjs().isAfter(endAt)) {
        setShowChallengeNotificationInfo({ show: true, message: 'error_challenge_end_time' });
        setShowHideButtonViewDashboard(true);
        return;
      }
    },
  });
  return (
    <>
      <Meta
        title={t('common:head.shareResultTitle', {
          displayName: learnerProfile?.displayName || ANONYMOUS_DISPLAY_NAME,
        })}
      />
      {(challengeInfoLoading || checkPointDataLoading) && <PageLoader />}

      {showUpgradeAccount ? <UpdateGradeAccountPage /> : null}

      {showChallengeNotificationInfo.show && (
        <ChallengeNoti
          messageError={showChallengeNotificationInfo.message}
          challengeCode={code}
          showHideButtonViewDashboard={showHideButtonViewDashboard}
          handlerClickViewDashboard={handlerClickViewDashboard}
        />
      )}
      {!showChallengeNotificationInfo.show &&
        !showUpgradeAccount &&
        challengeInfo?.challenges[0] &&
        checkPointData?.checkPoint && (
          <Container className="page-waiting-challenge">
            {showEndPopupSession && (
              <EndChallenge
                setShowEndPopupSession={setShowEndPopupSession}
                onSubmit={onSubmitEndChallenge}
              />
            )}
            <Countdown
              ref={countdownRef}
              onCountdownFinished={onCountdownFinished}
              hasSoundEffect={challengeInfo?.challenges[0]?.settings?.sound}
            />
            <img
              alt="background-page-challenge"
              src={optionThemeSelectEd?.background || backgroundPage}
            />

            <Header
              renderContent={renderTitle}
              size="large"
              onBackAction={onBackAction}
              states={{
                handlerOnOffSound,
                showChangeTheme,
                isShowEndSession,
                showEndPopupSession,
                showButtonViewDashboard: true,
                showListRightButton: true,
                showCodeButton: false,
              }}
              statesAction={{
                setHandlerOnOffSound,
                setShowChangeTheme,
                handlerClickViewDashboard,
                setShowEndPopupSession,
              }}
              isShowButtonAction={true}
            />
            {showCountdownTop && (
              <div className={CountdownBox}>
                <CountdownTop
                  date={
                    Date.now() +
                    (dayjs(challengeInfo?.challenges[0]?.startAt).valueOf() -
                      dayjs(Date.now()).valueOf())
                  }
                  renderer={renderer}
                  onComplete={onCompleteCountdown}
                />
              </div>
            )}

            <Body>
              <WrapChallenge>
                <ChallengeInfo
                  checkPoint={checkPointData?.checkPoint}
                  examStructures={examStructures}
                  maxScoreByExam={questionsByExam}
                  isPractice={isHidden}
                />
                <BoxInviteMember
                  onShareDirectLink={handleShareDirectLink}
                  onSubmit={handleSubmit(handleStartChallenge)}
                  code={code}
                  isShowHover={isNotPermissionPlayChallenge}
                  isDisabled={
                    messageError.messageError === 'error_challenge_start_time' ||
                    userName?.length === 0 ||
                    showCountdownTop ||
                    isNotPermissionPlayChallenge
                  }
                />
                <BoxListUserChallenge>
                  <BoxListUserChallengeInner className={ScrollbarStyle}>
                    <NumberUserChallenge>
                      <img className={IconUserChallengeImg} alt="avatar" src={IconUserChallenge} />
                      {t('number_players')} {playersCount}
                    </NumberUserChallenge>

                    <ListUser>
                      {challengePlayers?.map((player, index) => {
                        return (
                          <UserInfo
                            currentProfile={learnerProfile}
                            userInfo={player}
                            key={player.id}
                            ranking={index}
                          />
                        );
                      })}
                    </ListUser>
                  </BoxListUserChallengeInner>
                </BoxListUserChallenge>
              </WrapChallenge>
            </Body>
          </Container>
        )}
    </>
  );
}
