import { gql } from '@apollo/client';

export const GET_PROVINCES = gql`
  query GET_PROVINCES {
    provinces {
      id
      name
    }
  }
`;

export const GET_SCHOOLS = gql`
  query GET_SCHOOLS($provinceId: bigint, $offset: Int) {
    schools(where: { province: { id: { _eq: $provinceId } } }, limit: 1000, offset: $offset) {
      id
      name
    }
  }
`;
export const GET_PROVINCE_BY_ID = gql`
  query GET_PROVINCE_BY_ID($id: bigint) {
    provinces(where: { id: { _eq: $id } }) {
      id
      name
    }
  }
`;

export const GET_SCHOOL_BY_ID = gql`
  query GET_SCHOOL_BY_ID($id: bigint) {
    schools(where: { id: { _eq: $id } }) {
      id
      name
      phone_number
      zip_code
      address
      province {
        id
        name
      }
    }
  }
`;
export const GET_STUDENT_CLASS = gql`
  query getClassStudent($id: String!) {
    student(id: $id) {
      id
      classStudent {
        joinedDate
        class {
          id
          code
          name
          type
        }
      }
    }
  }
`;

export const GET_CLASS_BY_CODE = gql`
  query getClassByCode($code: String!) {
    classByCode(code: $code) {
      id
      name
      code
      type
    }
  }
`;

export const GET_TEST_LEVELS = gql`
  query testLevels {
    testLevels: test_levels(order_by: { order: asc }) {
      id
      name
      description
      descriptionEn: description_en
      closed
      metadata
      checkPoints: check_points(order_by: { order: asc }) {
        id
        name
        name_en
        order
        is_premium
      }
      slug
      __typename
    }
  }
`;

// export const GET_CHALLENGE_BY_ID = gql`
//   query ($id: ID!) {
//     challenge(id: $id) {
//       id
//       name
//       code
//       checkPoint {
//         id
//       }
//       learnerProfile {
//         id
//       }
//     }
//   }
// `;

export const GET_CHECK_POINTS_EXAM_STRUCTURES = gql`
  query ($id: uuid!) {
    examStructures: exam_structures(order_by: { order: asc }) {
      examType: exam_type
      displayName: display_name
      order
    }
    checkPoint: check_points_by_pk(id: $id) {
      id
      name
      name_en
      attempts_allowed
      type
      time
      testLevel: test_level {
        id
        name
        type
        examSettings: exam_settings {
          examType: exam_type
          executionTime: execution_time
        }
      }
      cpQuestions: cp_questions(order_by: { order: asc }) {
        question {
          id
          name
          title
          examType: exam_type
          questionType: question_type
          contents {
            id
            content
          }
          subquestions(order_by: { order: asc }) {
            id
            title
            titlePrefix: title_prefix
            detailPrefix: detail_prefix
            choices
            content
            shuffle
            isExample: is_example
            order
            details(order_by: { order: asc }) {
              id
              detail
              example {
                result
              }
              prefix
              order
            }
          }
          mark {
            total
          }
          maxScore: max_score
        }
      }
    }
  }
`;

// export const GET_CHALLENGE = gql`
//   query ($id: ID!) {
//     challenge(id: $id) {
//       id
//       name
//       code
//       checkPoint {
//         id
//       }
//       learnerProfile {
//         id
//       }
//     }
//   }
// `;

export const GET_CHALLENGE_BY_CODE = gql`
  query GetChallengeByCodeQuery($code: String!) {
    challenges(where: {code: {_eq: $code}}) {
      id
      name
      code
      isMixed: is_mixed
      startAt: start_at
      endAt: end_at
      learnerProfile: learner_profile {
        id
      }
      checkPoint: check_point {
        id
        testLevel: test_level {
          id
          name
        }
      }
      settings
    }
  }
`;

export const GET_CHALLENGE_PLAYERS = gql`
  query (
    $where: challenge_ranking_bool_exp!
    $limit: Int
    $offset: Int
    $order: [challenge_ranking_order_by!]
  ) {
    challengeRankingAggregate: challenge_ranking_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    challengeRankingPlayers: challenge_ranking(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order
    ) {
      id
      learnerProfile: learner_profile {
        id
        displayName: display_name
        avatarUrl: avatar_id
        dateOfBirth: date_of_birth
        gender
        isAnonymousUser: is_anonymous
      }
      totalScore: total_score
      rank
    }
  }
`;

export const GET_CHALLENGE_RANKING = gql`
  query ($challengeId: bigint!, $learnerProfileId: bigint!) {
    challengeRankingPlayer: challenge_ranking(
      where: {
        challenge_id: { _eq: $challengeId }
        learner_profile_id: { _eq: $learnerProfileId }
        exam_attempt_id: { _is_null: false }
      }
    ) {
      id
      learnerProfile: learner_profile {
        id
        displayName: display_name
        avatarUrl: avatar_id
        isAnonymousUser: is_anonymous
      }
      totalScore: total_score
      rank
    }
    challengeRanking: challenge_ranking(
      where: { challenge_id: { _eq: $challengeId }, exam_attempt_id: { _is_null: false } }
    ) {
      id
      learnerProfile: learner_profile {
        id
        displayName: display_name
        avatarUrl: avatar_id
        isAnonymousUser: is_anonymous
      }
      totalScore: total_score
      rank
    }
  }
`;

export const GET_LATEST_QUESTION_ATTEMPT_BY_PROFILE = gql`
  query getLatestAttempt($where: user_attempts_bool_exp!) {
    user_attempts(where: $where, order_by: { created_at: desc }, limit: 1) {
      id
      submitted_at
      created_at
      question_attempts(order_by: { updated_at: asc }) {
        id
        question {
          id
          name
          exam_type
        }
        updated_at
      }
    }
  }
`;
export const CHECK_PHONE_NUMBER_EXITS = gql`
  query checkPhoneExits($hash: String!) {
    check_phone(hash: $hash) {
      isExist
    }
  }
`;
export const GET_KEY_PUB = gql`
  query getKeyPub($data: String!) {
    list_event_pubsub(data: $data) {
      data
    }
  }
`;

export const GET_THEME_BACKGROUND_FOR_WEB = gql`
  query getBackGround($where: themes_bool_exp!) {
    themes(where: $where, order_by: { id: asc }) {
      id
      image_id
      name
    }
  }
`;
export const GET_DATA_FINISH_CHALLENGE = gql`
  query challengeEnd($challengeId: String!) {
    challenge_end(challengeId: $challengeId) {
      challenge_id
      id
      rank
      learner_profile {
        avatarUrl
        displayName
        id
        isAnonymousUser
      }
      created_at
      learner_profile_id
      submitted_at
      total_score
      listPart {
        id
        answers {
          status
          id
          order
        }
        order
      }
    }
  }
`;
export const GET_LAST_PLACEMENT_TEST = gql`
  query getLastPlacementTest($where: user_attempts_bool_exp) {
    user_attempts(where: $where, order_by: { created_at: desc }, limit: 1) {
      id
      check_point {
        id
        name
        test_level {
          type
        }
      }
    }
  }
`;
export const CHECK_CHALLENGE_EXTENDS = gql`
  query checkChallengeExtends($where: challenges_bool_exp) {
    challenges(where: $where) {
      is_limit
      is_hidden_name
      id
    }
  }
`;
export const COUNT_SUBMIT = gql`
  subscription countSubmit($where: user_attempts_bool_exp) {
    userAttempts: user_attempts_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
