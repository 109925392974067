import { AlertComponent } from '../../../../../components/common/alert-component';
import backgroundImage from '../../../../../assets/images/confirm-modal-end-session.png';
import ButtonEnd from '../../../../../assets/images/button-red.svg';
import ButtonContinues from '../../../../../assets/images/button-green.svg';
import { css, styled } from 'goober';
import React from 'react';
import { useTranslation } from 'react-i18next';
const contentCss = css`
  position: absolute;
  top: 1vw;
  width: 100%;
`;
const titleCss = css`
  width: 100%;
  text-align: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 2vw;
  padding-left: 4vw;
  color: #37f9f7;
`;
const popupContainer = css`
  position: relative;
  max-width: 40vw;
  & .body-content {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 0.8vw;
    color: #ffffff;
    width: 23vw;
  }
  & .body {
    padding-top: 3vw;
    float: right;
    padding-right: 2vw;
    clear: both;
  }
  & .body-button {
    padding-top: 2vw;
  }
`;
const ButtonShareLink = styled('button')`
  width: 10.462vw;
  height: 3.264vw;
  margin: 0 0.5vw;
  border: none;
  background: url(${(props) => props?.imgBg}) 0/100% no-repeat;
  transition: 0.3s;
  &:hover {
    background: url(${(props) => props.imgBg}) 0/100% no-repeat;
  }

  img {
    width: 1.178vw;
    padding-right: 0.5vw;
  }
  & span {
    position: relative;
    color: #ffffff;
    font-weight: bold;
    font-size: 0.7vw;
    text-transform: uppercase;
  }
`;

const EndChallenge = ({ setShowEndPopupSession, onSubmit }) => {
  const { t } = useTranslation(['create_challenge']);

  return (
    <AlertComponent>
      <div className={popupContainer}>
        <img src={backgroundImage} alt="" />
        <div
          className="span-close-popup-end-session"
          onClick={() => setShowEndPopupSession(false)}
        ></div>
        <div className={contentCss}>
          <div className={titleCss}>{t('title_popup_end')}</div>
          <div className="body">
            <p className="body-content">{t('body_content_end')}</p>
            <div className="body-button">
              <ButtonShareLink imgBg={ButtonEnd}>
                <div
                  className={css({
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  })}
                  onClick={onSubmit}
                >
                  <span>{t('title_button_end')}</span>
                </div>
              </ButtonShareLink>
              <ButtonShareLink imgBg={ButtonContinues}>
                <div
                  className={css({
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  })}
                >
                  <span onClick={() => setShowEndPopupSession(false)}>
                    {t('title_button_left')}
                  </span>
                </div>
              </ButtonShareLink>
            </div>
          </div>
        </div>
      </div>
    </AlertComponent>
  );
};
export default EndChallenge;
