import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function Meta({ title, description, fbShareThumb }) {
  const { t } = useTranslation('common');

  return (
    <Helmet>
      <title>{title || t('head.title')}</title>
      <meta name="description" content={description || t('head.description')} />

      {/* google */}
      <meta itemProp="name" content={title || t('head.title')} />
      <meta itemProp="description" content={description || t('head.description')} />

      {/* facebook */}
      <meta property="og:title" content={title || t('head.title')} />
      <meta property="og:description" content={description || t('head.description')} />
      {fbShareThumb && <meta property="og:image" content={fbShareThumb} />}
      <meta property="og:type" content="website" />
    </Helmet>
  );
}
