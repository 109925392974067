import React from 'react';
import HelmetComponent from '../common/helmet';

export const NotFoundPage = () => {
  return (
    <React.Fragment>
      <HelmetComponent title="Not found page" />

      <div style={{ textAlign: 'center' }}>
        <div
          onClick={() => {
            window.top.postMessage('HOME', '*')
          }}
        >
          Go to Home
        </div>
      </div>
    </React.Fragment>
  );
};
