import React, { useEffect, useRef } from 'react';
import { css, styled } from 'goober';
import goBackButton from '../../assets/images/createChallenge/button-back.png';
import backIcon from '../../assets/images/createChallenge/left.png';
import backgroundTitle from '../../assets/images/createChallenge/background-title.png';
import backgroundTitleLarge from '../../assets/images/createChallenge/bg-header-large.png';
import { useTranslation } from 'react-i18next';
import ButtonNoImage from '../../screen/JoinChallenge/components/Button/buttonNoImage';
import IconHeart from '../../../assets/images/icon-heart.svg';
import IconSound from '../../../assets/images/sound-turn.svg';
import ChangeTheme from '../ChangeTheme/Change-theme';

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  max-height: 5vw;
  position: relative;
  z-index: 23;
  margin-left: 0.6vw;
  margin-right: 0.6vw;
`;

const ButtonGoBack = styled('div')`
  display: flex;
  position: relative;
  width: 16.276041666666668vw;
  display: flex;
  align-items: center;
  margin-top: 2vw;
  font-family: 'OpenSans';

  & > img {
    position: absolute;
    width: 100%;
  }

  & .bg {
    height: 3vw;
  }

  & img ~ img {
    width: 2vw;
    left: 3vw;
  }

  & > span {
    position: absolute;
    font-size: 1.3020833333333333vw;
    font-weight: bold;
    color: #feffff;
    left: 8.7vw;
    text-transform: uppercase;
  }
`;
const goBackcss = css`
  width: 26.276041666666668vw !important;
`;
const BoxTitle = styled('div')`
  position: relative;
  width: 33vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'OpenSans';

  & > img {
    position: absolute;
    top: 0;
  }
`;
const DefaultTitle = styled('span')`
  position: relative;
  color: white;
  font-size: 1.7vw;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'OpenSans';
`;

const WrapContainer = (props) =>
  css({
    height: props.height,
  });

export default function Header({
  renderContent,
  size = 'normal',
  onBackAction,
  states = {},
  statesAction = {},
  isShowButtonAction = false,
}) {
  const { t } = useTranslation(['common', 'create_challenge']);
  const {
    setHandlerOnOffSound,
    setShowChangeTheme,
    handlerClickViewDashboard,
    setShowEndPopupSession,
    onCopyCode,
  } = statesAction;
  const {
    handlerOnOffSound,
    showChangeTheme,
    isShowEndSession,
    showEndPopupSession,
    showButtonViewDashboard,
    showListRightButton,
    showCodeButton,
    isFromNoti,
  } = states;
  const listDropdownRef = useRef(null);
  useEffect(() => {
    const HandlerShowHideChangeTheme = (event) => {
      if (showChangeTheme && listDropdownRef && !listDropdownRef?.current?.contains(event.target)) {
        setShowChangeTheme(false);
      }
    };
    document.addEventListener('mousedown', HandlerShowHideChangeTheme);
    return document.removeEventListener('click', HandlerShowHideChangeTheme);
  }, [showChangeTheme]);
  return (
    <Container className={WrapContainer({ height: size === 'large' ? '3vw' : '5vw' })}>
      <div className="list-button-header-left">
        <ButtonGoBack onClick={onBackAction}>
          <img className="bg" alt="back-background" src={goBackButton} />
          <img alt="back-icon" src={backIcon} />
          <span>{t('back')}</span>
        </ButtonGoBack>
        {isShowButtonAction && showButtonViewDashboard && (
          <ButtonNoImage
            title={t('create_challenge:view_dashboard')}
            onClickButton={handlerClickViewDashboard}
          />
        )}
        {showCodeButton && (
          <ButtonNoImage
            title={`${t('create_challenge:code')} : ${showCodeButton}`}
            onClickButton={onCopyCode}
          />
        )}
      </div>
      <BoxTitle className="titleHeader">
        <img alt="back" src={size === 'large' ? backgroundTitleLarge : backgroundTitle} />
        {(renderContent && renderContent()) || <DefaultTitle>Title</DefaultTitle>}
      </BoxTitle>
      {isShowButtonAction && showListRightButton && (
        <div className="lis-button-right">
          {isShowEndSession && (
            <ButtonNoImage
              onClickButton={() => setShowEndPopupSession(!showEndPopupSession)}
              title={t('create_challenge:title_button_end')}
              color={'#FF4842'}
            />
          )}
          <div className="choose-theme-action" ref={listDropdownRef}>
            <ButtonNoImage
              title={t('create_challenge:chose_a_theme')}
              icon={IconHeart}
              onClickButton={() => setShowChangeTheme(!showChangeTheme)}
            />
            {showChangeTheme && <ChangeTheme />}
          </div>
          <ButtonNoImage
            title={
              handlerOnOffSound
                ? t('create_challenge:sound') + ' : ' + t('create_challenge:on')
                : t('create_challenge:sound') + ' : ' + t('create_challenge:off')
            }
            icon={IconSound}
            onClickButton={() => setHandlerOnOffSound(!handlerOnOffSound)}
          />
        </div>
      )}
      {!isShowButtonAction && <ButtonGoBack className={goBackcss} />}
      {isFromNoti && <ButtonGoBack className={goBackcss} />}
    </Container>
  );
}
