import React from 'react';
import { styled, css } from 'goober';
import modalBackgroundOverlayImage from '../../assets/images/bg-modal-overlay.png';
import modalBackgroundImage from '../../assets/images/bg-modal.png';
import modalTopLeftCornerImage from '../../assets/images/ico-popup-corner-1.png';
import modalBottomLeftCornerImage from '../../assets/images/ico-popup-corner-3.png';
import modalBottomRightCornerImage from '../../assets/images/ico-popup-corner-4.png';
import modalTopRightCornerImage from '../../assets/images/ico-popup-corner-2.png';
import modalBorderBottomImage from '../../assets/images/ico-popup-border-bottom.png';

export const StyledModalOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.url ? `url(${props.url}) no-repeat center center fixed` : 'rgba(0, 0, 0, 0.5)')};
  z-index: 9999;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  flex-direction: column;
  overflow: hidden auto;
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #000000;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #2dfdfe;
    border-radius: 10px;
  }
`;

const StyledModal = styled('div')((props) => ({
  width: '39.4vw',
  height: '40.838370565vw',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  color: 'white',
  alignItems: 'center',
  fontFamily: 'OpenSans-Regular',
  '@media (max-width: 768px)': {
    width: '78vw',
    height: '82vw',
    padding: '3.5vw 5.25vw',
  },
  '@media (min-width: 768px)': {
    width: '60vw',
    height: '63vw',
    padding: '1.5vw 2.25vw',
  },
  '@media (min-width: 1024px)': {
    width: '39.4vw',
    height: '40.838370565vw',
    padding: '1.5vw 2.25vw',
  },
}));

const StyledModalCorner = styled('div')((props) => ({
  position: 'absolute',
}));
const ModalContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  width: 100%;
  margin-top: 1.5vw;
  overflow: auto;
  height: 30vw;
  @media (max-width: 768px) {
    height: 52vw;
    margin-top: 2.4vw;
  }
  @media (min-width: 768px) {
    height: 52vw;
    margin-top: 2vw;
  }
  @media (min-width: 1024px) {
    height: 30vw;
    margin-top: 1.5vw;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #000000;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #2dfdfe;
    border-radius: 10px;
  }
`;

const Title = styled('span')({
  fontFamily: 'OpenSans-ExtraBold',
  fontSize: '2.8125vw',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color: '#37f9f7',
  lineHeight: 1,
  '@media (max-width: 768px)': {
    fontSize: '5.8125vw',
  },
  '@media (min-width: 768px)': {
    fontSize: '4.8125vw',
  },
  '@media (min-width: 1024px)': {
    fontSize: '2.8125vw',
  },
});

const SubTitle = styled('span')({
  fontFamily: 'OpenSans-Regular',
  textTransform: 'uppercase',
  fontSize: '1.4174332019273vw',
  '@media (max-width: 768px)': {
    fontSize: '2.9174332019273vw',
  },
  '@media (min-width: 768px)': {
    fontSize: '2.6vw',
  },
  '@media (min-width: 1024px)': {
    fontSize: '1.4174332019273vw',
  },
});

const CornerTopLeft = css({
  top: '-1.5vw',
  left: '-0.5vw',
  width: '6.482698204vw',
  height: '8.935611038vw',
  '@media (max-width: 768px)': {
    top: '-3.5vw',
    left: '-2.2vw',
    width: '14.482698204vw',
    height: '19.935611038vw',
  },
  '@media (min-width: 768px)': {
    top: '-2.5vw',
    left: '-1.5vw',
    width: '11.482698204vw',
    height: '15.935611038vw',
  },
  '@media (min-width: 1024px)': {
    top: '-1.5vw',
    left: '-0.5vw',
    width: '6.482698204vw',
    height: '8.935611038vw',
  },
});

const CornerTopRight = css({
  top: '-1.5vw',
  right: '-1vw',
  width: '7.4901445466491vw',
  height: '7.3587385019711vw',
  cursor: 'pointer',
  '@media (max-width: 768px)': {
    top: '-2.5vw',
    right: '-1.5vw',
    width: '15.490145vw',
    height: '15.358739vw',
  },
  '@media (min-width: 768px)': {
    top: '-1.5vw',
    right: '-1vw',
    width: '11.4901445466491vw',
    height: '11.3587385019711vw',
  },
  '@media (min-width: 1024px)': {
    top: '-1.5vw',
    right: '-1vw',
    width: '7.4901445466491vw',
    height: '7.3587385019711vw',
  },
});
const CornerBottomLeft = css({
  bottom: '-4vw',
  left: '-1.5vw',
  width: '8.2347787998248vw',
  height: '9.7855227882038vw',
  '@media (max-width: 768px)': {
    bottom: ' -8vw',
    left: ' -2.5vw',
    width: '16.234779vw',
    height: '19.785523vw',
  },
  '@media (min-width: 768px)': {
    bottom: ' -5vw',
    left: ' -2.5vw',
    width: '12.234779vw',
    height: '14.785523vw',
  },
  '@media (min-width: 1024px)': {
    bottom: '-4vw',
    left: '-1.5vw',
    width: '8.2347787998248vw',
    height: '9.7855227882038vw',
  },
});
const CornerBottomRight = css({
  bottom: '-1.75vw',
  right: '-3vw',
  width: '11.6951379763469vw',
  height: '9.2422251423565vw',
  '@media (max-width: 768px)': {
    bottom: ' -2.75vw',
    right: '-5vw',
    width: '21.695138vw',
    height: '17.242225vw',
  },
  '@media (min-width: 768px)': {
    bottom: '-2.75vw',
    right: '-4vw',
    width: '17.6951379763469vw',
    height: '14.2422251423565vw',
  },
  '@media (min-width: 1024px)': {
    bottom: '-1.75vw',
    right: '-3vw',
    width: '11.6951379763469vw',
    height: '9.2422251423565vw',
  },
});

const BottomIcon = css({
  bottom: '-4.25vw',
  right: '11vw',
  width: '8.5851949189663vw',
  height: '5.9132720105125vw',
  '@media (max-width: 768px)': {
    bottom: '-8.25vw',
    right: '21.6vw',
    width: '18.5851949189663vw',
    height: '12.2132720105125vw',
  },
  '@media (min-width: 768px)': {
    bottom: '-7.25vw',
    right: '17vw',
    width: '14.5851949189663vw',
    height: '9.9132720105125vw',
  },
  '@media (min-width: 1024px)': {
    bottom: '-4.25vw',
    right: '11vw',
    width: '8.5851949189663vw',
    height: '5.9132720105125vw',
  },
});

const Space = styled('span')({
  display: 'flex',
  minHeight: '20px',
  flex: 1,
});
export const backgroundUrl = (image) => `url(${image}) no-repeat center 0/100%`;
const Dialog = ({
  children,
  open = true,
  title,
  subtitle,
  transparent = false,
  onClose,
  className,
  containerClassName,
}) => {
  return (
    <StyledModalOverlay url={!transparent && modalBackgroundOverlayImage} style={{ display: open ? 'flex' : 'none' }}>
      <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
        <Space />
        <StyledModal
          className={`${css({
            background: backgroundUrl(modalBackgroundImage),
          })}
        ${className}`}
        >
          <StyledModalCorner
            className={`${css({
              background: backgroundUrl(modalTopLeftCornerImage),
            })} ${CornerTopLeft}`}
          />
          <StyledModalCorner
            className={`${css({
              background: backgroundUrl(modalBottomLeftCornerImage),
            })} ${CornerBottomLeft}`}
          />
          <StyledModalCorner
            className={`${css({
              background: backgroundUrl(modalBottomRightCornerImage),
            })} ${CornerBottomRight}`}
          />
          <StyledModalCorner
            className={`${css({
              background: backgroundUrl(modalBorderBottomImage),
            })} ${BottomIcon}`}
          />
          <StyledModalCorner
            className={`${css({
              background: backgroundUrl(modalTopRightCornerImage),
            })} ${CornerTopRight}`}
            onClick={onClose}
          />
          {title && <Title className={css({ marginTop: '1vw' })}>{title}</Title>}
          {subtitle && <SubTitle>{subtitle}</SubTitle>}
          <ModalContentWrapper className={`${containerClassName}`}>{children}</ModalContentWrapper>
        </StyledModal>
        <Space />
      </div>
    </StyledModalOverlay>
  );
};

export default React.memo(Dialog);
