import { css, styled } from 'goober';
import React from 'react';
import backgroundPage from '../../assets/images/createChallenge/backgroundPage.png';
import backgroundChallengeErrorPage from '../../assets/images/bg-challenge-error.png';
import imgBgUpgrade from '../../assets/images/bg-upgrade-account.svg';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import { useHistory } from 'react-router-dom';
import imgBtnBlue from '../../assets/images/btn-blue-svg.svg';
import { handlerClickBack } from '../../../configs/urls';

const Container = styled('div')`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  font-family: 'OpenSans', serif;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .absolute {
    position: absolute;
  }
`;

const Body = styled('div')`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -0.6vw;
`;
const ChallengeNotiBox = styled('div')`
  position: relative;
  width: 39.9vw;
  height: 36.911vw;
  padding: 3vw 4.529vw;
  background: url(${backgroundChallengeErrorPage}) no-repeat 0/100% !important;
  background-size: 100% 100% !important;
`;
const ChallengeNotiButton = styled('div')`
  position: relative;
  margin: -2vw auto 0 0.4vw;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ChallengeNotiImg = css`
  img {
    max-width: 4.529vw;
  }
`;
const joinChallengeButtonClassName = css`
  font-size: 0.906vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #feffff;
  margin-top: 1.15vw;
  span {
    min-width: 14vw !important;
  }
`;
const lineBreakText = css`
  width: 15.851vw;
  font-size: 0.725vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin-top: 1.15vw;
`;
const TitleHeader = styled('span')`
  position: relative;
  color: #00ffc6;
  font-size: 1vw;
  font-weight: bold;
  text-align: center;
  padding: 0 1.65vw;
`;

export default function UpdateGradeAccountPage() {
  const { t } = useTranslation('common');
  let history = useHistory();
  const onBackAction = () => {
    window.top.postMessage('HOME', '*');
  };

  const onGoCreateChallenge = () => {
    history.push('/join-challenge/join/create-challenge');
  };
  const renderTitle = React.useCallback(() => {
    return <TitleHeader>{t('upgradeAccountNoti')}</TitleHeader>;
  }, [t]);

  return (
    <Container>
      <img alt="background-page" src={backgroundPage} />
      <Header renderContent={renderTitle} size="large" onBackAction={onBackAction} />
      <Body>
        <ChallengeNotiBox>
          <div
            className={css`
              text-align: center;
              color: #ffffff;
              font-size: 1.1vw;
              margin-top: 1vw;
              font-weight: 700;
            `}
          >
            {t('upgradeAccountTitle')}
          </div>
          <ChallengeNotiImg
            className={css`
              position: absolute;
              top: 30%;
              left: 45%;
              transform: translate(-50%, -12%);
            `}
          >
            <img
              alt="background-page"
              src={imgBgUpgrade}
              className={css`
                margin-left: 3vw;
                width: 18vw;
                height: 18vw;
              `}
            />
            <ChallengeNotiButton>
              <button
                className={css`
                  position: absolute;
                  width: 22vw;
                  height: 4vw;
                  bottom: -4vw;
                  color: #fff;
                  font-size: 1vw;
                  left: 1.4vw;
                  background-image: url(${imgBtnBlue});
                  background-size: contain;
                  background-repeat: no-repeat;
                  border: none;
                  outline: none;
                  background-color: transparent;
                  text-transform: uppercase;
                  font-weight: 700;
                `}
                onClick={() => window.open('https://checkout.flyer.vn/page/products', '_blank')}
              >
                {t('common:upgradeAccount')}
              </button>
            </ChallengeNotiButton>
          </ChallengeNotiImg>
        </ChallengeNotiBox>
      </Body>
    </Container>
  );
}
