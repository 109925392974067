import { useEffect, useMemo } from 'react';
export default function useShareZalo(props) {
  const { customize = true, oaid = '579745863508352884', href } = props || {};
  useEffect(() => {
    let script = document.querySelector('#zaloScript');
    if (!script) {
      script = document.createElement('script');
      script.src = 'https://sp.zalo.me/plugins/sdk.js';
      script.id = 'zaloScript';
      document.body.append(script);
    } else {
      window.ZaloSocialSDK?.reload();
    }

    const interval = window.setInterval(() => {
      const btn = document.querySelector('.zalo-share-button');
      if (window.ZaloSocialSDK && btn) {
        window.clearInterval(interval);
        window.ZaloSocialSDK.reload();
      }
    }, 500);
  }, []);
  const attributes = useMemo(() => {
    const payload = {
      'data-customize': customize,
      'data-oaid': oaid,
    };
    if (href) {
      payload['data-href'] = href;
    }
    return payload;
  }, [customize, href, oaid]);
  return attributes;
}
