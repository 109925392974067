import React from 'react';
import HelmetComponent from '../../components/common/helmet';
import { styled, css } from 'goober';
import { useTranslation } from 'react-i18next';
import BgError from '../assets/images/bg-error.jpg';
import BgButtonYellow from '../assets/images/bg-btn-error.png';
import IconLogo from '../assets/images/ic-flyer.png';
import HeaderError from '../components/Header/TitleHasLogo';
import '../styles/fonts.css';
// import { RANKING_URL } from '../../configs/urls';
const ErrorBody = styled('div')`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  background: url(${BgError}) no-repeat 0/100%;
  background-position: top center;
  background-size: cover !important;
  flex-direction: column;
`;
const ErrorContent = styled('div')`
  font-family: 'OpenSans-Regular';
  width: 69.01vw;
  height: 28.646vw;
  margin: 6vw auto auto auto;
  text-align: center;
`;
const ErrorTitle = styled('div')`
  color: #58eff1;
  font-size: 2.808vw;
  text-align: center;
  margin-bottom: 2.582vw;
  font-weight: bold;
  font-family: 'OpenSans-ExtraBold';
`;
const ErrorDesc = styled('div')`
  color: #fff;
  font-size: 1.313vw;
  text-align: center;
  margin-bottom: 2.582vw;
  padding: 0 5.906vw;
`;
const ErrorButton = styled('div')`
  width: 38.359vw;
  height: 12.318vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -3vw auto 0 auto;
  font-family: 'OpenSans-Bold';
  font-size: 1.54vw;
`;
const backHome = css`
  font-size: 1.54vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background: transparent;
  border: 0;
  font-family: 'OpenSans-Bold';
  position: relative;
  margin-top: -0.25vw;
  width: 22.826vw;
  height: 4.937vw;
  background: url(${BgButtonYellow}) no-repeat 0/100%;
  padding-left: 3.5vw;
`;
const TitleHeader = styled('span')`
  position: relative;
  color: #00ffc6;
  font-size: 1vw;
  font-weight: bold;
  text-align: center;
  padding: 0 1.65vw;
  margin-top: 1.453vw;

  img {
    max-width: 8.605vw;
  }
`;
export const UserHasNotExam = () => {
  const { t } = useTranslation(['error']);
  const renderTitle = React.useCallback(() => {
    return (
      <TitleHeader>
        <a href="./">
          <img alt="Flyer" src={IconLogo} />
        </a>
      </TitleHeader>
    );
  }, []);
  const onBackAction = () => {
    window.location.replace(`${window.location.origin}`);
  };
  return (
    <>
      <HelmetComponent title="Not found page" />
      <ErrorBody>
        <HeaderError renderContent={renderTitle} size="large" onBackAction={onBackAction} />
        <ErrorContent>
          <ErrorTitle>{t('error_title_no_exam')}</ErrorTitle>
          <ErrorDesc>
            {t('error_desc_no_exam1')}
            <br />
            {t('error_desc_no_exam2')}
          </ErrorDesc>
          <ErrorButton>
            <button
              className={backHome}
              onClick={() => window.location.replace(`${window.location.origin}`)}
            >
              {t('error_button_no_exam')}
            </button>
          </ErrorButton>
        </ErrorContent>
      </ErrorBody>
    </>
  );
};
