import { useEffect, useMemo } from 'react';
import crypto from 'browserify-aes';
export const onShareFB = (link) => {
  ((o, _e, _t, _n) => {
    const r = window.screen.width / 2 - 335;
    const i = window.screen.height / 2 - 250;
    window.open(
      o,
      'facebook-share-dialog',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=true, copyhistory=no, width=670, height=500, top=${i}, left=${r}`,
    );
  })(
    `https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=${encodeURIComponent(
      link,
    )}&display=popup&ref=plugin&src=share_button`,
  );
};
export const encrypt = (text) => {
  if (!text) {
    return;
  }

  const keyString = process.env.REACT_APP_KEY_ENCRYPT;
  const ivString = process.env.REACT_APP_IV_ENCRYPT;
  if (keyString && ivString) {
    const iv = Buffer.from(ivString, 'hex');
    const key = Buffer.from(keyString, 'hex');
    const cipher = crypto.createCipheriv('aes-256-cbc', key, iv);
    let encrypted = cipher.update(text);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return encrypted.toString('hex');
  }
  return undefined;
};

export function decrypt(hash) {
  if (!hash) return;
  const keyString = process.env.REACT_APP_KEY_ENCRYPT;
  const ivString = process.env.REACT_APP_IV_ENCRYPT;
  if (keyString && ivString) {
    const iv = Buffer.from(ivString, 'hex');
    const key = Buffer.from(keyString, 'hex');
    const encryptedText = Buffer.from(hash, 'hex');
    const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
  }
  return undefined;
}
