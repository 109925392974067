import getMediaUrl from '../../utils/helpers/getMediaUrl';
import backgroundBookDefault from '../../assets/themes/Default/book1.png';
import iconNextDefault from '../../assets/themes/Default/btn-next.png';
import iconPreDefault from '../../assets/themes/Default/btn-prev.png';
import iconTimeRightDefault from '../../assets/themes/Default/box-time-header-right.png';
import iconCloseLeftDefault from '../../assets/themes/Default/box-time-header-left.webp';
import backgroundProgressBarWrapDefault from '../assets/images/progress-bar-back-ground.webp';
import { THEME_OPTION } from '../../utils/constants/themes';
import backgroundBookLager from '../../assets/images/book_featured.png';
import buttonCloseLeft from '../../assets/images/btn-close.png';

const useThemes = (dataThemes) => {
  if (!dataThemes) return;
  const listData = dataThemes?.themes?.map((item) => {
    return {
      id: item.id,
      name: item.name,
      background: getMediaUrl(item.image_id, 'image'),
      type: 'default',
      challengeMode: true,
      backgroundBookMultiChose: backgroundBookDefault,
      backgroundBookLager: backgroundBookLager,
      buttonCloseLeft: buttonCloseLeft,
      iconNext: iconNextDefault,
      iconPre: iconPreDefault,
      backgroundTimeRight: iconTimeRightDefault,
      backgroundCloseLeft: iconCloseLeftDefault,
      hasIconCloseLeft: true,
      backgroundProgressBar: 'default',
      backgroundProgressBarWrap: backgroundProgressBarWrapDefault,
      iconNearBookLeft: null,
      iconNearBookRight: null,
    };
  });
  const listThemesFinal = listData?.concat(THEME_OPTION) || [];

  return {
    listThemesFinal,
  };
};
export default useThemes;
