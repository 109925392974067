import { useMutation, useQuery } from '@apollo/client';
import { useCookie } from 'react-use';
// import { useCallback } from 'react';
// import { ROOT_DOMAIN_URL } from '../../configs/urls';
// import { CREATE_CHALLENGE_ANONYMOUS_USER, UPDATE_ANONYMOUS_USER } from '../graphql/mutation';
// import { GET_ANONYMOUS_USER } from '../graphql/query';
import useAuth from '../../hooks/use-auth';
import { gql } from '@apollo/client';

export const GET_ANONYMOUS_USER = gql`
  query GetViewerSession {
    viewerSession {
      learnerProfile: anonymousLearnerProfile {
        id: learnerProfileId
        displayName
        isAnonymousUser: isAnonymous
      }
    }
  }
`;

export default function useLearnerProfile() {
  const { currentProfile } = useAuth();

  const [anonymousLearnerProfileId, updateAnonymousLearnerProfileId] = useCookie(
    process.env.REACT_APP_COOKIE_ANONYMOUS_LEARNER_PROFILE_ID || 'anonymous_learner_profile_id',
  );

  const { data: anonymousData, loading } = useQuery(GET_ANONYMOUS_USER, {
    skip: !anonymousLearnerProfileId || !currentProfile.isAnonymousUser,
    context: { v2: true },
  });

  // const [createAnonymousUser] = useMutation(CREATE_CHALLENGE_ANONYMOUS_USER);

  // const [updateAnonymousUser] = useMutation(UPDATE_ANONYMOUS_USER);

  // const upsertLearnerProfile = useCallback(
  //   async ({ firstName }) => {
  //     if (!currentProfile.isAnonymousUser) {
  //       return currentProfile;
  //     }

  //     if (anonymousData?.learnerProfile) {
  //       if (firstName && anonymousData.learnerProfile.firstName !== firstName) {
  //         const { data: updatedData } = await updateAnonymousUser({
  //           variables: { id: anonymousData.learnerProfile.id, firstName },
  //         });

  //         const learnerProfile = updatedData?.learnerProfileUpdate?.learnerProfile;
  //         updateAnonymousLearnerProfileId(learnerProfile.id, { domain: ROOT_DOMAIN_URL });

  //         return learnerProfile;
  //       }

  //       return anonymousData.learnerProfile;
  //     }

  //     const { data: createdData } = await createAnonymousUser({
  //       variables: {
  //         input: { firstName: firstName || 'Flyer Member' },
  //       },
  //     });

  //     const learnerProfile = createdData?.learnerProfileCreate?.learnerProfile;
  //     updateAnonymousLearnerProfileId(learnerProfile.id, { domain: ROOT_DOMAIN_URL });

  //     return learnerProfile;
  //   },
  //   [
  //     anonymousData?.learnerProfile,
  //     createAnonymousUser,
  //     currentProfile,
  //     updateAnonymousLearnerProfileId,
  //     updateAnonymousUser,
  //   ],
  // );

  return [
    currentProfile.isAnonymousUser ? anonymousData?.viewerSession?.learnerProfile : currentProfile,
    () => {},
    loading,
  ];
}
