import React from 'react';
import { styled, css } from 'goober';
import BgVioletButtonCenter from '../../assets/images/bg-button-violet-center.png';
import BgVioletButtonPressLeft from '../../assets/images/bg-button-violet-press-left.png';
import BgVioletButtonPressCenter from '../../assets/images/bg-button-violet-press-center.png';
import BgVioletButtonPressRight from '../../assets/images/bg-button-violet-press-right.png';
import BgRedButtonLeft from '../../assets/images/bg-button-red-left.png';
import BgRedButtonCenter from '../../assets/images/bg-button-red-center.png';
import BgRedButtonRight from '../../assets/images/bg-button-red-right.png';
import BgRedButtonHoverLeft from '../../assets/images/bg-button-red-hover-left.png';
import BgRedButtonHoverCenter from '../../assets/images/bg-button-red-hover-center.png';
import BgRedButtonHoverRight from '../../assets/images/bg-button-red-hover-right.png';
import BgRedButtonPressLeft from '../../assets/images/bg-button-red-press-left.png';
import BgRedButtonPressCenter from '../../assets/images/bg-button-red-press-center.png';
import BgRedButtonPressRight from '../../assets/images/bg-button-red-press-right.png';
import BgRedButtonDisabledLeft from '../../assets/images/bg-button-red-disabled-left.png';
import BgRedButtonDisabledCenter from '../../assets/images/bg-button-red-disabled-center.png';
import BgRedButtonDisabledRight from '../../assets/images/bg-button-red-disabled-right.png';

const Button = styled('button')`
  min-width: fit-content;
  height: 3.8vw;
  padding: 0 0.97vw;
  text-transform: capitalize;
  border: none;
  cursor: pointer;
  text-align: center;
  font-family: 'OpenSans-Bold';
  font-size: 1.4vw;
  color: rgb(254, 254, 254);
  line-height: 1.2;
  text-shadow: 0px 2px 7.36px rgba(0, 0, 0, 0.74);
  background-color: transparent;
  -moz-transform: 'matrix(0.91299748242464, 0, 0, 0.91299748242464, 0, 0)';
  -webkit-transform: 'matrix(0.91299748242464, 0, 0, 0.91299748242464, 0, 0)';
  -ms-transform: 'matrix(0.91299748242464, 0, 0, 0.91299748242464, 0, 0)';
  &:before {
    content: '';
    height: 3.8vw;
    top: 0;
    left: 0;
    width: 1.2vw;
  }
  &:after {
    content: '';
    height: 3.85vw;
    top: 0;
    right: 0;
    width: 1.2vw;
  }
  @media (max-width: 768px) {
    height: 8.2vw;
    padding: 0 2.5vw;
    &:before {
      height: 8.2vw;
      width: 2.57vw;
    }
    &:after {
      height: 8.2vw;
      width: 2.57vw;
    }
  }
  @media (min-width: 768px) {
    height: 7.2vw;
    padding: 0 2.28vw;
    &:before {
      height: 7.2vw;
      width: 2.262301vw;
    }
    &:after {
      height: 7.2vw;
      width: 2.262301vw;
    }
  }
  @media (min-width: 1024px) {
    height: 3.8vw;
    padding: 0 0.97vw;
    &:before {
      height: 3.8vw;
      width: 1.2vw;
    }
    &:after {
      height: 3.85vw;
      width: 1.2vw;
    }
  }
`;
const Title = styled('span')((props) => ({
  minHeight: '3.8vw',
  height: '3.8vw',
  lineHeight: '3.8vw',
  display: 'inline-block',
  padding: '0px',
  maxWidth: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  '@media (max-width: 768px)': {
    height: '8.2vw',
    minHeight: '8.2vw',
    lineHeight: '8.2vw',
    fontSize: '2.8vw',
    padding: '0 5px',
  },
  '@media (min-width: 768px)': {
    height: '7.2vw',
    minHeight: '7.2vw',
    lineHeight: '7.2vw',
    fontSize: '2.2vw',
    padding: '0 5px',
  },
  '@media (min-width: 1024px)': {
    height: '3.8vw',
    minHeight: '3.8vw',
    lineHeight: '3.8vw',
    fontSize: '1.1vw',
    padding: '0px',
  },
}));
const BlueButtonCls = (props) =>
  css({
    position: 'relative',
    width: 'auto',
    padding: '0 0.97vw',
    maxWidth: '250px',
    height: '2.717vw',
    '@media (max-width: 768px)': {
      padding: '0 2.5vw',
    },
    '@media (min-width: 768px)': {
      padding: '0 2.18vw',
    },
    '@media (min-width: 1024px)': {
      padding: '0 0.97vw',
    },
    '&:before': {
      height: '2.717vw',
      width: '0.977vw',
      position: 'absolute',
      background: `url(${BgRedButtonHoverLeft}) no-repeat 0/100%;`,
    },
    '&:after': {
      height: '2.717vw',
      width: '1.269vw',
      position: 'absolute',
      background: `url(${BgRedButtonHoverRight}) no-repeat 0/100%;`,
    },

    '>span': {
      height: '2.717vw',
      minHeight: '2.717vw',
      lineHeight: '2.717vw',
      background: `url(${BgRedButtonHoverCenter}) no-repeat 0/100%;`,
      backgroundSize: '100% 93%',
    },

    '&:hover': {
      '&:before': {
        background: `url(${BgRedButtonLeft}) no-repeat 0/100%;`,
      },
      '&:after': {
        background: `url(${BgRedButtonRight}) no-repeat 0/100%;`,
      },
      '>span': {
        background: `url(${BgRedButtonCenter}) no-repeat 0/100%;`,
        backgroundSize: '100% 93%',
      },
    },
    '&:active': {
      '&:before': {
        background: `url(${BgVioletButtonPressLeft}) no-repeat 0/100%;`,
      },
      '&:after': {
        background: `url(${BgVioletButtonPressRight}) no-repeat 0/100%;`,
      },
      '>span': {
        background: `url(${BgVioletButtonPressCenter}) no-repeat 0/100%;`,
        backgroundSize: '100% 93%',
      },
    },
    '&:disabled': {
      opacity: 0.5,
    },
  });

const RedButtonCls = (props) =>
  css({
    position: 'relative',
    width: 'auto',
    maxWidth: '200px',
    height: '2.717vw',
    fontSize: '0.906vw',
    padding: '0',
    '@media (max-width: 768px)': {
      padding: '0 2.5vw',
    },
    '@media (min-width: 768px)': {
      padding: '0 2.18vw',
    },
    '@media (min-width: 1024px)': {
      padding: '0 1vw',
    },
    '&:before': {
      height: '2.717vw',
      width: '1.042vw',
      position: 'absolute',
      background: `url(${BgRedButtonLeft}) no-repeat 0/100%;`,
    },
    '&:after': {
      height: '2.717vw',
      width: '1.359vw',
      position: 'absolute',
      background: `url(${BgRedButtonRight}) no-repeat 0/100%;`,
    },
    '>span': {
      background: `url(${BgRedButtonCenter}) no-repeat 0/100%;`,
      backgroundSize: '100% 98%',
      height: '2.717vw',
      minHeight: '2.717vw',
      lineHeight: '2.717vw',
    },

    '&:hover': {
      '&:before': {
        height: '2.717vw',
        background: `url(${BgRedButtonHoverLeft}) no-repeat 0/100%;`,
      },
      '&:after': {
        height: '2.717vw',
        background: `url(${BgRedButtonHoverRight}) no-repeat 0/100%;`,
      },
      '>span': {
        background: `url(${BgRedButtonHoverCenter}) no-repeat 0/100%;`,
        backgroundSize: '100% 98%',
        height: '2.717vw',
      },
    },

    '&:active': {
      '&:before': {
        background: `url(${BgRedButtonPressLeft}) no-repeat 0/100%;`,
      },
      '&:after': {
        background: `url(${BgRedButtonPressRight}) no-repeat 0/100%;`,
      },
      '>span': {
        background: `url(${BgRedButtonPressCenter}) no-repeat 0/100%;`,
        backgroundSize: '100% 98%',
      },
    },

    '&:disabled': {
      cursor: 'default',
      pointerEvents: 'none',
      '&:before': {
        background: `url(${BgRedButtonDisabledLeft}) no-repeat 0/100%;`,
      },
      '&:after': {
        background: `url(${BgRedButtonDisabledRight}) no-repeat 0/100%;`,
      },
      '>span': {
        background: `url(${BgRedButtonDisabledCenter}) no-repeat 0/100%;`,
        backgroundSize: '100% 98%',
      },
    },
  });

export default function ButtonCustom({
  id,
  onClick,
  isViolet,
  isRed,
  title,
  className,
  disabled,
  btnKey,
  classNameTitle,
}) {
  return (
    <Button
      id={id}
      key={btnKey}
      onClick={onClick}
      disabled={disabled}
      className={` ${isRed ? RedButtonCls() : BlueButtonCls()}
          ${className}
      `}
    >
      <Title background={BgVioletButtonCenter} className={classNameTitle}>
        {title}
      </Title>
    </Button>
  );
}
