import './configs/firebase';

import React from 'react';
import { createRoot } from 'react-dom/client';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { setup } from 'goober';

// START Include base css
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/index.css';
// END Include base css

import App from './app';

import './i18n';

dayjs.extend(duration);
setup(React.createElement);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

Sentry.init({
  dsn: 'https://db18dccb179745a6a44bb4010cc1eeb8@o4504218971602944.ingest.sentry.io/4504219158183936',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

root.render(<App />);
