import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    zIndex: ({ zIndexInc }) => 99 + zIndexInc,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export function AlertComponent({ children, zIndexInc = 0, backgroundColor = 'rgba(0,0,0,0.90)' }) {
  const classes = useStyles({ zIndexInc, backgroundColor });

  return (
    <div id="alert" className={classes.root}>
      {children}
    </div>
  );
}
