import { useCallback, useState } from 'react';
import useSWR from 'swr';
import { Link, useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import youtubeVideoWrap from '../../assets/images/youtube-video-wrap.png';
import { useTranslation } from 'react-i18next';

const baseUrl = 'https://youtube.googleapis.com/youtube/v3/playlistItems';
const maxResults = 6;
const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;

export default function SpeakingListing() {
  const history = useHistory();
  const { playlistTestLevelId } = useParams();
  const [playlistId, testLevelId] = playlistTestLevelId.split('__');
  const fetchPlaylistItems = useCallback(
    async (url, pageToken) => {
      const response = await fetch(
        `${url}?part=snippet&key=${apiKey}&playlistId=${playlistId}&maxResults=${maxResults}${
          pageToken ? `&pageToken=${pageToken}` : ''
        }`,
      );
      return await response.json();
    },
    [playlistId],
  );
  const [pageToken, setPageToken] = useState(null);
  const [page, setPage] = useState(1);
  const { data = { items: [] } } = useSWR([baseUrl, pageToken], fetchPlaylistItems);
  const [videoInfo, setVideoInfo] = useState({});

  const { t } = useTranslation(['common']);
  const handlerGoBack = useCallback(() => {
    // history.goBack();
    window.top.postMessage('GO_BACK_OR_HOME', '*');
  }, []);
  return (
    <div className="wrapper-mover">
      <div className="main-speaking">
        <div
          className={clsx(
            'top-speaking d-flex align-items-center justify-content-between',
            videoInfo.id && 'speaking-details',
          )}
        >
          {videoInfo.id ? (
            <span className="btn-trove" onClick={() => setVideoInfo({})}>
              {t('back')}
            </span>
          ) : (
            <div onClick={handlerGoBack} className="btn-trove">
              {t('back')}
            </div>
          )}

          <span className="title"></span>
          {/* <a href="#" className="btn-chonngaunhien"></a> */}
        </div>
        <div className="lst-question-exam d-flex flex-wrap">
          {videoInfo.id && (
            <div className="speaking-video-wrap" allowFullScreen="allowFullScreen">
              <div className="speaking-video">
                <iframe
                  title={videoInfo.title}
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${videoInfo.id}`}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen="allowFullScreen"
                ></iframe>
                <img src={youtubeVideoWrap} alt="youtube video wrap" />
              </div>
              <p>{videoInfo.title}</p>
            </div>
          )}
          {data.items.map((item) => {
            return (
              <span
                key={item.id}
                onClick={() => {
                  setVideoInfo({
                    id: item.snippet.resourceId.videoId,
                    title: item.snippet.title,
                  });
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                className="item-exam"
              >
                {/* <span className="checked"></span> */}
                <img
                  src={
                    item.snippet.thumbnails.standard?.url || item.snippet.thumbnails.default?.url
                  }
                  alt=""
                />
                <span className="avatar"></span>
                <p>
                  <span className="title">{item.snippet.title}</span>
                </p>
              </span>
            );
          })}
        </div>

        <div className="box-control-exam">
          <span
            onClick={() => {
              if (data.prevPageToken) {
                setPageToken(data.prevPageToken);
                setPage(page - 1);
              } else {
                setPage(1);
              }
            }}
            className="btn-prev"
          ></span>
          <span
            onClick={() => {
              if (data.nextPageToken) {
                setPageToken(data.nextPageToken);
                setPage(page + 1);
              } else {
                setPage(Math.ceil(data.pageInfo.totalResults / data.pageInfo.resultsPerPage));
              }
            }}
            className="btn-next"
          ></span>
          {data.pageInfo && (
            <span className="paging">
              {page}/{Math.ceil(data.pageInfo.totalResults / data.pageInfo.resultsPerPage)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
