import { styled } from 'goober';
import React from 'react';
import backgroundPage from '../../assets/images/createChallenge/backgroundPage.png';
import backgroundChallengeErrorPage from '../../assets/images/bg-challenge-error.png';
import imgChallengeErrorPage from '../../assets/images/img-challenge-error.png';
import { useTranslation } from 'react-i18next';
import Button from '../../components/buttons/ButtonDefault';
import ButtonCustom from '../../components/buttons/ButtonCustom';
import Header from '../../components/Header';
import { useHistory } from 'react-router-dom';
import { css } from 'goober';
import { handlerClickBack, RANKING_URL } from '../../../configs/urls';

const Container = styled('div')`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  font-family: 'OpenSans';

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .absolute {
    position: absolute;
  }
`;

const Body = styled('div')`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -0.6vw;
`;
const ChallengeNotiBox = styled('div')`
  position: relative;
  width: 39.9vw;
  height: 36.911vw;
  padding: 3vw 4.529vw;
  background: url(${backgroundChallengeErrorPage}) no-repeat 0/100% !important;
  background-size: 100% 100% !important;
`;
const ChallengeNotiButton = styled('div')`
  position: relative;
  margin: -4vw auto 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ChallengeNotiImg = css`
  img {
    max-width: 4.529vw;
  }
`;
const joinChallengeButtonClassName = css`
  margin-top: 1.985vw;
  font-size: 0.906vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #feffff;
  margin-top: 1.15vw;
  span {
    min-width: 14vw !important;
  }
`;
const lineBreakText = css`
  width: 15.851vw;
  font-size: 0.725vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin-top: 1.15vw;
`;
const TitleHeader = styled('span')`
  position: relative;
  color: #00ffc6;
  font-size: 1vw;
  font-weight: bold;
  text-align: center;
  padding: 0 1.65vw;
`;

export default function ChallengeNoti({
  messageError,
  challengeCode,
  showHideButtonViewDashboard,
  handlerClickViewDashboard,
}) {
  const { t } = useTranslation(['join_challenge']);
  let history = useHistory();
  const onBackAction = () => {
    window.top.postMessage('HOME', '*');
  };

  const onGoCreateChallenge = () => {
    // history.push('/join-challenge/join/create-challenge');
    window.top.postMessage({ key: 'CREATE_CHALLENGE', value: '' }, '*');
  };
  const renderTitle = React.useCallback(() => {
    return <TitleHeader>{t(messageError)}</TitleHeader>;
  }, [messageError, t]);

  return (
    <Container>
      <img alt="background-page" src={backgroundPage} />
      <Header
        renderContent={renderTitle}
        size="large"
        onBackAction={onBackAction}
        statesAction={{ handlerClickViewDashboard }}
        isShowButtonAction={true}
        states={{
          showButtonViewDashboard: showHideButtonViewDashboard,
          isFromNoti: true,
          showListRightButton: false,
        }}
      />
      <Body>
        <ChallengeNotiBox>
          <ChallengeNotiImg>
            <img alt="background-page" src={imgChallengeErrorPage} />
            <ChallengeNotiButton>
              <ButtonCustom
                title={t('see_ranking')}
                className={joinChallengeButtonClassName}
                onClick={() =>
                  window.open(`${RANKING_URL}/?challengeCode=${challengeCode}`, '_blank')
                }
              />
              <div className={lineBreakText}>{t('or_txt')}</div>
              <Button
                isRed
                title={t('create_challenge')}
                className={joinChallengeButtonClassName}
                onClick={onGoCreateChallenge}
              />
            </ChallengeNotiButton>
          </ChallengeNotiImg>
        </ChallengeNotiBox>
      </Body>
    </Container>
  );
}
