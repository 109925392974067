import confetti4 from '../../../../assets/animation/confetti.json';
import { css } from 'goober';
import Lottie from 'react-lottie';
const animationCss = css`
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
`;
const animationLeftBottom = css`
  position: absolute;
  left: 0;
  bottom: 100px;
`;
const animationRightBottom = css`
  position: absolute;
  right: 0;
  bottom: 100px;
`;
const Confetti = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: confetti4,
    click: false,
  };
  return (
    <div className={animationCss}>
      <div className="animation-top">
        <Lottie
          options={defaultOptions}
          height={'56.96875vw'}
          width={'100%'}
          isClickToPauseDisabled={true}
        />
      </div>
      <div className={animationLeftBottom}>
        <Lottie
          options={defaultOptions}
          height={'100%'}
          width={600}
          isClickToPauseDisabled={true}
        />
      </div>
      <div className={animationRightBottom}>
        <Lottie
          options={defaultOptions}
          height={'100%'}
          width={400}
          isClickToPauseDisabled={true}
        />
      </div>
    </div>
  );
};

export default Confetti;
