import { css, keyframes, styled } from 'goober';
import { Howl } from 'howler';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledModalOverlay } from '../Dialog';

const scaleUp = keyframes`
	0% {
		opacity: 0;
		transform: scale(1.5);
	}
	5% {
		opacity: 1;
		transform: scale(1.25);
	}
	10% {
		opacity: 1;
		transform: scale(1.25);
	}
	20% {
		opacity: 0;
		transform: scale(1.5);
	}
	50% {
		opacity: 0;
		transform: scale(1.5);
	}
	100% {
		opacity: 0;
		transform: scale(1.25);
	}
`;

const Text = styled('span')`
  color: rgb(230, 255, 230);
  font-size: 5vw;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  display: block;
  transition: all 1.5s ease;
  opacity: 0;
  transform: scale(1.25);
  position: absolute;
  transform: translate(-50%, -50%);
  &:nth-child(1) {
    animation: ${scaleUp} 11s ease;
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation: ${scaleUp} 11s ease;
    animation-delay: 1s;
  }
  &:nth-child(3) {
    animation: ${scaleUp} 11s ease;
    animation-delay: 2s;
  }
  &:nth-child(4) {
    animation: ${scaleUp} 11s ease;
    animation-delay: 3s;
  }
  &:nth-child(5) {
    animation: ${scaleUp} 11s ease;
    animation-delay: 4s;
  }
  &:nth-child(6) {
    animation: ${scaleUp} 10s ease;
    animation-delay: 5s;
  }
`;

const Countdown = React.forwardRef(({ onCountdownFinished, hasSoundEffect = false }, ref) => {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const { t } = useTranslation('common');
  const timer = React.useRef(null);

  React.useEffect(() => {
    if (hasSoundEffect && showOverlay) {
      const countdownSound = new Howl({
        src: ['/countdown.mp3'],
      });
      countdownSound.play();
    }
  }, [hasSoundEffect, showOverlay]);
  const stopCountdown = React.useCallback(() => {
    setShowOverlay(false);
    onCountdownFinished();
  }, [onCountdownFinished]);

  const startCountdown = React.useCallback(() => {
    setShowOverlay(true);
    timer.current = setTimeout(() => stopCountdown(), 7000);
  }, [stopCountdown]);

  React.useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  React.useImperativeHandle(
    ref,
    () => ({
      showOverlay,
      startCountdown,
    }),
    [startCountdown, showOverlay],
  );

  return (
    <StyledModalOverlay
      style={{
        display: showOverlay ? 'flex' : 'none',
        opacity: showOverlay ? 1 : 0,
        background: 'rgba(0, 0, 0, 0.8)',
      }}
    >
      <div
        ref={ref}
        className={css({
          height: '30%',
          width: '100%',
          background: 'rgba(0, 0, 0, 0.85)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        })}
      >
        <Text>5</Text>
        <Text>4</Text>
        <Text>3</Text>
        <Text>2</Text>
        <Text>1</Text>
        <Text>{t('start')}</Text>
      </div>
    </StyledModalOverlay>
  );
});

Countdown.propTypes = {
  onCountdownFinished: PropTypes.func,
  hasSoundEffect: PropTypes.bool,
};

export default Countdown;
