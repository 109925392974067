import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const i18nConfig = {
  defaultLocale: 'vi',
  rootDomains: [
    {
      domain: 'flyer.us',
      defaultLocale: 'en',
    },
  ],
};

function getDefaultLocale() {
  let defaultLocale = i18nConfig.defaultLocale;
  const rootDomain = i18nConfig.rootDomains.find(({ domain }) =>
    window.location.hostname.endsWith(domain),
  );
  if (rootDomain) {
    defaultLocale = rootDomain.defaultLocale;
  }

  return defaultLocale;
}

export const defaultLocale = getDefaultLocale();

class MyDetector {
  name = 'customPath';
  lookup() {
    const pathName = window.location.pathname;
    return pathName.startsWith('/en') ? 'en' : pathName.startsWith('/vi') ? 'vi' : defaultLocale;
  }
}

const myDetector = new MyDetector();

export const currentLocale = myDetector.lookup();

const languageDetector = new LanguageDetector();
languageDetector.addDetector(myDetector);

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: defaultLocale,
    react: {
      useSuspense: false,
    },
    detection: {
      order: ['customPath', 'cookie'],
    },
    ns: ['common'],
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${Date.now()}`,
    },
  });

export default i18n;
