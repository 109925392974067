import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/storage';
import "firebase/remote-config";

firebase.initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
const remoteConfig = firebase.remoteConfig();

remoteConfig.defaultConfig = {
	force_verify_phone: false
}
remoteConfig.settings.minimumFetchIntervalMillis = 60000;

firebase.analytics();

export { remoteConfig }
