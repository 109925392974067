// import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IconCheckTrue from '../../../assets/images/check-true.svg';
import IconCheckFalse from '../../../assets/images/check-false.png';
import IconGoal from '../../../assets/images/goald-icon.svg';
import { useTranslation } from 'react-i18next';

const ProcessBar = ({ totalAnswerInfo = {}, isSessionEnd }) => {
  /*
   * tính phần trăm câu đúng, sai của tổng số câu đung sai, 60 la with tong
   */
  const { t } = useTranslation(['create_challenge']);

  let leftTruePercent = (60 * totalAnswerInfo?.answerCorrect) / totalAnswerInfo.totalQuestion;

  let RightFalsePercent = (60 * totalAnswerInfo?.answerWrong) / totalAnswerInfo.totalQuestion;
  const positonAccuracy = (totalAnswerInfo.accuracy / 100) * 60 - 1.5;
  if (isSessionEnd) {
    const spaceBetweenAccuracyAndLeftTrue = positonAccuracy + 1.5 - leftTruePercent;
    // console.log(spaceBetweenAccuracyAndLeftTrue);
    leftTruePercent = spaceBetweenAccuracyAndLeftTrue + leftTruePercent;
    RightFalsePercent = 60 - leftTruePercent - 1.5;
  }

  return (
    <div className="container-process">
      <div
        className={`left-true ${
          totalAnswerInfo.answerWrong !== 0 && totalAnswerInfo.answerCorrect === 0
            ? 'false-all'
            : ''
        }`}
        style={{ width: leftTruePercent + 'vw' }}
      >
        <div className="icon" data-tip={totalAnswerInfo.answerCorrect || 0}>
          <img src={IconCheckTrue} alt="check-icon" />
        </div>
        <div className="number" data-tip={totalAnswerInfo.answerCorrect || 0}>
          {totalAnswerInfo.answerCorrect || 0}
        </div>
      </div>
      <div
        className={`accuracy ${
          (totalAnswerInfo.answerWrong !== 0 && totalAnswerInfo.answerCorrect === 0) ||
          (totalAnswerInfo.answerCorrect !== 0 && totalAnswerInfo.answerWrong === 0)
            ? 'true-or-false-all'
            : ''
        }`}
        style={
          !totalAnswerInfo.accuracy
            ? { left: 'calc(50% - 3.5vw)' }
            : { left: positonAccuracy + 'vw' }
        }
      >
        <img src={IconGoal} alt="goal flyer" />
        <p className="text-title">{t('accuracy')}</p>
        <p className="current-position">
          {' '}
          {Number.parseFloat(totalAnswerInfo.accuracy || 0).toFixed(2)}%
        </p>
      </div>
      <div
        className={`right-false ${
          totalAnswerInfo.answerCorrect !== 0 && totalAnswerInfo.answerWrong === 0 ? 'true-all' : ''
        }`}
        style={{ width: RightFalsePercent + 'vw' }}
      >
        <span className="number" data-tip={totalAnswerInfo.answerWrong || 0}>
          {totalAnswerInfo.answerWrong || 0}
        </span>
        <span className="icon" data-tip={totalAnswerInfo.answerWrong || 0}>
          <img src={IconCheckFalse} alt="check-icon" />
        </span>
      </div>
    </div>
  );
};
export default ProcessBar;
