import { css, styled } from 'goober';
import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import getMediaUrl from '../../../utils/helpers/getMediaUrl';
import { useAtom } from 'jotai';
import { handlerOnOffSoundAtom, listThemeOptionAtom, themeSelectEdAtom } from '../../hook/common';
import Header from '../../components/Header';
import CountdownTop from 'react-countdown';
import dayjs from 'dayjs';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import bgCountdownTop from '../../assets/images/bg-countdown-top.png';
import IconNotCheck from '../../assets/images/icon-not-check.svg';
import IconCheckEd from '../../assets/images/icon-checked.svg';
import backgroundPage from '../../assets/images/createChallenge/backgroundPage.png';

import { gql, useMutation, useQuery, useSubscription } from '@apollo/client';
import {
  GET_CHALLENGE_BY_CODE,
  GET_DATA_FINISH_CHALLENGE,
  GET_KEY_PUB,
  GET_THEME_BACKGROUND_FOR_WEB,
} from '../../graphql/query';
import PageLoader from '../../../components/public/page-loader';
import { useTranslation } from 'react-i18next';
import ProcessBar from './Component/process-bar';
import IconLeftTop from '../../assets/images/icon-left-top-popup.svg';
import IconRightTop from '../../assets/images/icon-right-top-popup.svg';
import IconBottom from '../../assets/images/icon-bottom-popup.svg';
import HeaderBg from '../../assets/images/title-bg-popup.png';
import Rank1 from '../../assets/images/rank-1.1.svg';
import Rank2 from '../../assets/images/rank-2.1.svg';
import Rank3 from '../../assets/images/rank-3.1.svg';
import FalseAnswer from '../../assets/images/false-anwer.svg';
import TrueAnswer from '../../assets/images/trueAnswer.svg';
import NotChangeAnswer from '../../assets/images/notChange.svg';
import { decrypt } from '../../utils/helper';
import PubNub from 'pubnub';
import useAuth from '../../../hooks/use-auth';
import { SUBSCRIPTION_CHALLENGE } from '../../graphql/subscription';
import defaultAvatar from '../../../v2/assets/images/ico-avatar-default.png';
import EndChallenge from '../JoinChallenge/components/EndChallenge/endChallenge';
import { END_SESSION_CHALLENGE } from '../../graphql/mutation';
import ReactTooltip from 'react-tooltip';
import { Howl } from 'howler';
import ItemUser from './Component/Item-user';
import { motion, Reorder, useAnimationControls } from 'framer-motion/dist/framer-motion';
import { get, orderBy } from 'lodash';
import Confetti from './Component/confetti';
import useThemes from '../../hook/useThemes';

const Container = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  font-family: 'OpenSans';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  & > img {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .absolute {
    position: absolute;
  }
`;
const CountdownBox = css`
  display: flex;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 0vw;
  .box-countdown {
    background: url(${bgCountdownTop}) no-repeat 0/100%;
    width: 22.92vw;
    height: 5.072vw;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'OpenSans';
    color: #fff;
    .box-item {
      padding: 0 0.25vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span.top {
        color: #00f6ff;
        font-size: 2.57vw;
        font-weight: bold;
        line-height: 2.9vw;
      }
      span {
        font-size: 0.453vw;
      }
    }
  }
`;
const TitleHeader = styled('span')`
  position: relative;
  color: #00ffc6;
  font-size: 1vw;
  font-weight: bold;
  text-align: center;
  padding: 0 1.65vw;
`;
const Body = styled('div')`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  margin-top: 6vw;
`;
const UserLeaderBoard = () => {
  const history = useHistory();
  const { t } = useTranslation(['join_challenge', 'common', 'create_challenge']);
  const [themeSelectEd, setThemeSelectEd] = useAtom(themeSelectEdAtom);
  const { code } = useParams();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const { user, currentProfile } = useAuth();
  const [handlerOnOffSound, setHandlerOnOffSound] = useAtom(handlerOnOffSoundAtom);
  const [showChangeTheme, setShowChangeTheme] = useState(false);
  const [showCountdownTop, setShowCountdownTop] = useState(false);
  const [showEndPopupSession, setShowEndPopupSession] = useState(false);

  const [challengeRankingPlayers, setChallengeRankingPlayers] = useState([]);
  const [totalAnswerInfo, setTotalAnswerInfo] = useState({});
  const [headerQuestion, setHeaderQuestion] = useState([]);
  const [listItemWhenFinal, setListItemWhenFinal] = useState([]);
  const [showAnimationBeforeEnd, setShowAnimationBeforeEnd] = useState(false);
  const [handlerCheckShowTopTen, setHandlerCheckShowTopTen] = useState(false);
  const [isSessionEnd, setIsSessionEnd] = useState(false);
  const [listThemeOption] = useAtom(listThemeOptionAtom);
  const controlsAnimate = useAnimationControls();
  const { data: challengeInfo, loading: challengeInfoLoading } = useQuery(GET_CHALLENGE_BY_CODE, {
    variables: { code },
  });

  const sound = React.useMemo(
    () =>
      new Howl({
        src: '/waiting-room.mp3',
        loop: true,
      }),
    [],
  );

  const challengeId = challengeInfo?.challenges[0]?.id;

  const { data: challengePlayersData } = useQuery(
    gql`
      query challengePlayers($challengeId: String!) {
        challengeRankingPlayers: get_challenge_ranking_player_live(challengeId: $challengeId) {
          id
          learnerProfile {
            id
            avatarUrl
            displayName
            isAnonymousUser
          }
          rank
          totalScore
          totalQuestion
          percentAnswer
          answerWrong
          answerCorrect
        }
      }
    `,
    {
      variables: { challengeId: challengeId?.toString() },
      fetchPolicy: 'network-only',
      skip: !challengeId,
      pollInterval: 10000
    },
  );

  const [onSubmitEndChallenge] = useMutation(END_SESSION_CHALLENGE, {
    variables: {
      id: challengeInfo?.challenges[0]?.id,
      input: {
        end_at: dayjs().format(),
      },
    },
  });

  const { data: totalAnswerInfoData } = useQuery(
    gql`
      query AnswerAccuracyQuery($challengeId: String!) {
        answerAccuracy: answer_accuracy(challengeId: $challengeId) {
          accuracy
          answerCorrect
          answerWrong
          totalQuestion
        }
      }
    `,
    {
      variables: { challengeId: challengeId?.toString() },
      skip: !challengeId,
      fetchPolicy: 'network-only',
      pollInterval: 10000
    },
  );

  useEffect(() => {
    if (totalAnswerInfoData?.answerAccuracy) {
      setTotalAnswerInfo(totalAnswerInfoData?.answerAccuracy);
    }
  }, [totalAnswerInfoData?.answerAccuracy]);

  // const { data: DataSubsCriptionChallenge } = useSubscription(SUBSCRIPTION_CHALLENGE, {
  //   variables: {
  //     where: {
  //       code: {
  //         _eq: code,
  //       },
  //     },
  //   },
  //   fetchPolicy: 'network-only',
  // });

  const { data: dataBackGround } = useQuery(GET_THEME_BACKGROUND_FOR_WEB, {
    variables: {
      where: {
        type: {
          _eq: 'challenge',
        },
      },
    },
  });

  const [, setListThemeOption] = useAtom(listThemeOptionAtom);
  const { listThemesFinal } = useThemes(dataBackGround || []);

  useEffect(() => {
    if (dataBackGround === undefined || dataBackGround === null) return;
    setListThemeOption(listThemesFinal);
    if (
      themeSelectEd === undefined ||
      themeSelectEd === null ||
      themeSelectEd === 0 ||
      typeof themeSelectEd === 'string'
    ) {
      setThemeSelectEd(100);
    }
  }, [dataBackGround, themeSelectEd]);

  useMemo(() => {
    if (showAnimationBeforeEnd) {
      console.log('vo 262');
      setIsSessionEnd(false);
      return;
    }
    if (isSessionEnd) return;
    if (
      challengeInfo?.challenges[0].endAt &&
      dayjs().isAfter(dayjs(challengeInfo?.challenges[0].endAt))
    ) {
      setIsSessionEnd(true);
      return;
    }
    setIsSessionEnd(false);
  }, [challengeInfo?.challenges[0].endAt, challengeInfo, showAnimationBeforeEnd]);

  const isShowEndSession = useMemo(() => {
    //endAt
    if (currentProfile?.isAnonymousUser) return false;
    const userCreateChallenge = challengeInfo?.challenges[0]?.learnerProfile?.id.toString();
    const currentUserId = currentProfile.id.toString();
    if (userCreateChallenge !== currentUserId) return false;
    if (isSessionEnd) return false;
    if (showAnimationBeforeEnd) return false;
    if (showCountdownTop) {
      return false;
    }
    return true;
  }, [currentProfile, challengeInfo, showCountdownTop, isSessionEnd, showAnimationBeforeEnd]);

  const isSessionStartEd = useMemo(() => {
    if (
      challengeInfo?.challenges[0].startAt === null ||
      (challengeInfo?.challenges[0].startAt &&
        dayjs(dayjs(challengeInfo?.challenges[0].startAt)).diff(dayjs(), 'millisecond') < 300)
    ) {
      return true;
    }
    return false;
  }, [challengeInfo?.challenges[0]?.startAt, showCountdownTop]);
  //'944' challengeId?.toString()
  const { data: dataWhenFinishChallenge, loading: loadingWhenGetFinish } = useQuery(
    GET_DATA_FINISH_CHALLENGE,
    {
      variables: { challengeId: challengeId?.toString() },
      fetchPolicy: 'network-only',
      skip: !isSessionEnd,
    },
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!isSessionEnd) return;
      if (!dataWhenFinishChallenge?.challenge_end) return;
      const dataHeader = dataWhenFinishChallenge?.challenge_end[0]?.listPart.map((item) => {
        return {
          part: item.order,
          countNumberQuestion: item.answers.length,
        };
      });
      setHeaderQuestion(dataHeader);
      setListItemWhenFinal(dataWhenFinishChallenge?.challenge_end);
    };
    fetchData();
    return fetchData;
  }, [isSessionEnd, dataWhenFinishChallenge, showAnimationBeforeEnd]);

  useEffect(() => {
    setChallengeRankingPlayers(challengePlayersData?.challengeRankingPlayers || []);
  }, [challengePlayersData]);

  // const { data: dataPubSub } = useQuery(GET_KEY_PUB, {
  //   fetchPolicy: 'network-only',
  //   variables: { data: challengeId?.toString() },
  //   skip: !challengeId,
  // });

  const onBackAction = () => {
    // history.push('/join-challenge/' + code);
    window.top.postMessage('BACK_ACTION', '*');
  };

  useEffect(() => {
    if (!showAnimationBeforeEnd) return;
    const timeShowBeforeEnd = window.setInterval(async () => {
      console.log('vao', 351);
      setShowAnimationBeforeEnd(false);
      setIsSessionEnd(true);
    }, 10000);
    return () => {
      window.clearInterval(timeShowBeforeEnd);
    };
  }, [showAnimationBeforeEnd]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return '';
    } else {
      // Render a countdown
      return (
        <div className="box-countdown">
          <div className="box-item">
            <span className="top">{days} : </span>
            <span>{t('day_countdown')}</span>
          </div>
          <div className="box-item">
            <span className="top">{hours} : </span>
            <span>{t('hour_countdown')}</span>
          </div>
          <div className="box-item">
            <span className="top">{minutes} : </span>
            <span>{t('minute_countdown')}</span>
          </div>
          <div className="box-item">
            <span className="top">{seconds}</span>
            <span>{t('second_countdown')}</span>
          </div>
        </div>
      );
    }
  };

  const renderTitle = React.useCallback(() => {
    return <TitleHeader>{challengeInfo?.challenges[0].name}</TitleHeader>;
  }, [challengeInfo]);

  const handlerEndSession = useCallback(() => {
    setShowAnimationBeforeEnd(true);
    setShowEndPopupSession(false);
    // su ly animate before end
    onSubmitEndChallenge().then();
  }, [showAnimationBeforeEnd]);

  const optionThemeSelectEd = React.useMemo(() => {
    return listThemeOption.find((item) => item.id === themeSelectEd);
  }, [themeSelectEd]);
  const onCompleteCountdown = useCallback(() => {
    //

    setShowCountdownTop(false);
  }, []);
  const handlerShowAnswer = useCallback((number) => {
    if (number === 0) {
      return NotChangeAnswer;
    } else if (number === 1) {
      return TrueAnswer;
    }
    return FalseAnswer;
  }, []);
  const onCopyCode = () => {
    navigator.clipboard.writeText(code);
  };
  // const handleMessage = useCallback(
  //   (event) => {
  //     const message = event.message;
  //
  //     if (event.channel === challengeId + '-join') {
  //       setChallengeRankingPlayers((current) => [
  //         ...current,
  //         {
  //           learnerProfile: message.learnerProfile,
  //           rank: 0,
  //           percentAnswer: 0,
  //           totalScore: 0,
  //           answerWrong: 0,
  //           answerCorrect: 0,
  //         },
  //       ]);
  //     } else if (event.channel === challengeId + '-submit') {
  //       setTotalAnswerInfo(event.message.totalAnswerInfo);
  //       setChallengeRankingPlayers((current) => {
  //         const newList = [...current];
  //         const itemIdx = newList.findIndex(
  //           (item) => item.learnerProfile.id.toString() === message.learnerProfile.id.toString(),
  //         );
  //
  //         const obj = {
  //           learnerProfile: event.message.learnerProfile,
  //           rank: event.message.studentRankInfo.rank,
  //           percentAnswer: event.message.studentAnswerInfo.percentAnswer,
  //           totalScore: event.message.studentRankInfo.totalScore || 0,
  //           answerWrong: event.message.studentAnswerInfo.answerWrong,
  //           answerCorrect: event.message.studentAnswerInfo.answerCorrect,
  //           totalQuestion: event.message.studentAnswerInfo.totalQuestion,
  //         };
  //
  //         if (itemIdx !== -1) {
  //           newList[itemIdx] = obj;
  //         } else {
  //           newList.push(obj);
  //         }
  //
  //         return orderBy(newList, ['totalScore'], ['desc']);
  //       });
  //     }
  //   },
  //   [challengeId],
  // );
  //ToDo : ti se check ky
  useEffect(() => {
    if (showAnimationBeforeEnd) {
      const newList = [...challengeRankingPlayers].slice(0, 3);
      setChallengeRankingPlayers(newList);
    }
  }, [showAnimationBeforeEnd]);
  const getImageRank = useCallback((rank) => {
    if (rank === 1) {
      return Rank1;
    } else if (rank === 2) {
      return Rank2;
    } else if (rank === 3) {
      return Rank3;
    }
    return null;
  }, []);

  useEffect(() => {
    if (handlerOnOffSound) {
      sound.play();
    } else {
      sound.stop();
    }
    return () => sound.stop();
  }, [challengeInfo, sound, handlerOnOffSound]);

  useEffect(() => {
    if (
      dayjs(challengeInfo?.challenges[0]?.startAt).valueOf() - dayjs(Date.now()).valueOf() >
      0
    ) {
      setShowCountdownTop(true);
    }
  }, [challengeInfo?.challenges[0]?.startAt]);

  // useEffect(() => {
  //   if (
  //     !challengeId ||
  //     (!currentProfile?.id && !user?.id) ||
  //     !dataPubSub?.list_event_pubsub?.data
  //   ) {
  //     return () => {};
  //   }
  //
  //   const dataDecrypt = decrypt(dataPubSub?.list_event_pubsub?.data);
  //   const pubNub = new PubNub({
  //     subscribeKey: dataDecrypt,
  //     userId: currentProfile?.id || user?.id,
  //   });
  //
  //   pubNub.subscribe({ channels: [challengeId + '-submit', challengeId + '-join'] });
  //   pubNub.addListener({ message: handleMessage });
  //
  //   return () => pubNub.unsubscribe({ channels: [challengeId + '-submit', challengeId + '-join'] });
  // }, [
  //   challengeId,
  //   currentProfile?.id,
  //   dataPubSub?.list_event_pubsub?.data,
  //   handleMessage,
  //   user?.id,
  // ]);
  useEffect(() => {
    if (!showAnimationBeforeEnd) return;
    controlsAnimate.start((i) => ({
      rotate: [0, 0, 0, 0, 0, 0, 3, -3, 3, -3, 0],
      y: 0,
      opacity: [0, 1],
      scale: [1.2, 1, 1.1, 1, 1],
      transition: { type: 'spring', duration: 2, delay: i * 2 },
    }));
  }, [showAnimationBeforeEnd]);

  //TODO: new logic for auto end session
  //  Truong hop hen gio va k an end session
  useEffect(() => {
    const endAt = challengeInfo?.challenges[0]?.endAt;
    if (!endAt) return;
    // k co end at thi return boi vi ham nay  chi chay de nghe su kien end session khi co  thoi gian hen gio end
    // neu da end roi thi show end luon
    if (dayjs(endAt).valueOf() - dayjs(Date.now()).valueOf() < -3000) {
      console.log('vao 456');
      setIsSessionEnd(true);
      return;
    }
    // chua end va van con thoi gian  thi settime out den luc end
    const timeMillisecond = dayjs(dayjs(endAt)).diff(dayjs(), 'millisecond');
    const timer = setTimeout(() => {
      if (dayjs(endAt).valueOf() - dayjs(Date.now()).valueOf() > -3000) {
        console.log('vao 554');
        setShowAnimationBeforeEnd(true);
        return;
      }
      setIsSessionEnd(true);
    }, timeMillisecond);
    return () => clearTimeout(timer);
  }, [challengeInfo?.challenges[0]?.endAt]);

  // subscription cho truong hop click end session

  useSubscription(SUBSCRIPTION_CHALLENGE, {
    variables: {
      where: {
        code: {
          _eq: code,
        },
      },
    },
    onSubscriptionData: (res) => {
      const endAt = get(res, 'subscriptionData.data.challenges[0].endAt');
      const userCreateChallenge = challengeInfo?.challenges[0]?.learnerProfile?.id;
      const currentUserId = currentProfile.id;
      // chi danh cho user khong phhai la user tao thach dau. boi vi user tao  thach dau co the tu an end hoac  hen gio end roi
      if (userCreateChallenge === currentUserId) return;
      if (dayjs().isAfter(endAt) && dayjs(endAt).valueOf() - dayjs(Date.now()).valueOf() > -6000) {
        setShowAnimationBeforeEnd(true);
      }
      // setIsSessionEnd(true);
    },
    skip: isSessionEnd,
  });
  return (
    <>
      {challengeInfoLoading && <PageLoader />}

      <Container layoutScroll>
        {showAnimationBeforeEnd && <Confetti />}
        {showEndPopupSession && (
          <EndChallenge
            setShowEndPopupSession={setShowEndPopupSession}
            onSubmit={handlerEndSession}
          />
        )}
        <img
          alt="background-page-challenge"
          src={optionThemeSelectEd?.background || backgroundPage}
        />
        <Header
          renderContent={renderTitle}
          size="large"
          onBackAction={onBackAction}
          states={{
            handlerOnOffSound,
            showChangeTheme,
            isShowEndSession,
            showButtonViewDashboard: false,
            showListRightButton: true,
            showCodeButton: code,
          }}
          statesAction={{
            setHandlerOnOffSound,
            showEndPopupSession,
            setShowEndPopupSession,
            setShowChangeTheme,
            onCopyCode,
          }}
          isShowButtonAction={true}
        />
        {showCountdownTop && (
          <div className={CountdownBox}>
            <CountdownTop
              date={
                Date.now() +
                (dayjs(challengeInfo?.challenges[0]?.startAt).valueOf() -
                  dayjs(Date.now()).valueOf())
              }
              renderer={renderer}
              onComplete={onCompleteCountdown}
            />
          </div>
        )}
        <Body>
          <ProcessBar totalAnswerInfo={totalAnswerInfo} isSessionEnd={isSessionEnd} />
          <div className="table-list-user">
            <div className="icon-top-left">
              <img src={IconLeftTop} alt="icon flyer" />
            </div>
            <div className="title-popup">
              <img src={HeaderBg} alt="" />
              <span>
                {isSessionEnd
                  ? t('create_challenge:leaderboardNt')
                  : t('create_challenge:leaderboard')}
              </span>
            </div>
            {!isSessionEnd && (
              <div className="box-table-content box-table-content-is-not-end">
                {isSessionStartEd &&
                  challengeRankingPlayers &&
                  challengeRankingPlayers.length > 10 && (
                    <div
                      className="show-top-10"
                      onClick={() => setHandlerCheckShowTopTen((statePrev) => !statePrev)}
                    >
                      <img src={handlerCheckShowTopTen ? IconCheckEd : IconNotCheck} alt="flyer" />
                      <label htmlFor="onlyTop10"> {t('create_challenge:onlytop10')}</label>
                    </div>
                  )}
                <div className="item-header">
                  <div className="left-header">
                    <span className="rank">{t('create_challenge:rank')}</span>
                    <span className="title-name">{t('create_challenge:name')}</span>
                  </div>
                  <span className="score"> {t('create_challenge:score')}</span>
                </div>
                <Reorder.Group
                  as={'div'}
                  draggable={false}
                  dragListener={false}
                  values={challengeRankingPlayers}
                  onReorder={setChallengeRankingPlayers}
                >
                  {challengeRankingPlayers.map((player = {}, index) => {
                    const profile = player.learnerProfile || {};
                    // tinh toan logic animation owr day
                    if (handlerCheckShowTopTen && index > 9) {
                      return;
                    }
                    return (
                      <Reorder.Item
                        key={profile.id}
                        dragListener={false}
                        value={player.rank}
                        as={motion.div}
                        draggable={false}
                        animate={controlsAnimate}
                        custom={index}
                      >
                        <ItemUser
                          profile={profile}
                          rank={index + 1}
                          isSessionStartEd={isSessionStartEd}
                          getImageRank={getImageRank}
                          player={player}
                          ref={createRef()}
                        />
                      </Reorder.Item>
                    );
                  })}
                </Reorder.Group>
              </div>
            )}
            {isSessionEnd && (
              <div className="box-table-content-end">
                <div className="item-header-left-end">
                  <div className="box-1">
                    {!!listItemWhenFinal && listItemWhenFinal.length > 0 && (
                      <div className="left-header">
                        <span className="rank">{t('create_challenge:rank')}</span>
                        <span className="title-name">{t('create_challenge:name')}</span>
                      </div>
                    )}
                  </div>
                  {listItemWhenFinal &&
                    listItemWhenFinal?.map((item, key) => (
                      <div className="box-2" key={item.id}>
                        <span className="rank">{item.rank}</span>
                        {item.rank <= 3 && (
                          <img
                            src={getImageRank(item.rank)}
                            alt="rank flyer"
                            className="img-ranking"
                          />
                        )}
                        {item.rank >= 4 && <div className="image-null"></div>}
                        <img
                          src={
                            item?.learner_profile?.avatarUrl
                              ? getMediaUrl(item?.learner_profile?.avatarUrl, 'avatar')
                              : defaultAvatar
                          }
                          alt="avatar"
                          className="avatar"
                        />
                        <span className="title-name">{item.learner_profile?.displayName}</span>
                      </div>
                    ))}
                </div>

                <div className="item-body-right-end">
                  <div className="box-1">
                    {headerQuestion &&
                      headerQuestion.length > 0 &&
                      headerQuestion.map((header, index) => (
                        <div className="right-header" key={index}>
                          <div className="part">
                            <div className="title-part"> Part {header.part}</div>
                            <div className="list-title-question">
                              {[...Array(header?.countNumberQuestion)].map((qs, index) => (
                                <div className="question-in-part" key={index}>
                                  Q{index + 1}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  {/*//handlerShowAnswer*/}
                  {!!listItemWhenFinal &&
                    listItemWhenFinal.map((item, key) => (
                      <div className="box-2" key={key}>
                        {item.listPart?.map((listItem, key) => (
                          <div
                            key={key}
                            className={`${
                              listItem?.answers.length <= 0 ? 'once-item' : ''
                            } group-answer`}
                          >
                            {listItem?.answers.map((answer, i) => (
                              <img src={handlerShowAnswer(answer.status)} alt="false" key={i} />
                            ))}
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
                <div className="score-right-end">
                  {!!listItemWhenFinal && listItemWhenFinal.length > 0 && (
                    <span className="title-name-score">{t('create_challenge:score')}</span>
                  )}
                  {!!listItemWhenFinal &&
                    listItemWhenFinal.map((value, index) => (
                      <div className="box-item-score" key={value.id + 'ggg'}>
                        <div className="total-score-end">{value?.total_score || 0}</div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            <div className="icon-top-right">
              <img src={IconRightTop} alt="icon flyer" />
            </div>
            <div className="icon-bottom">
              <img src={IconBottom} alt="icon flyer" />
            </div>
          </div>
          <ReactTooltip globalEventOff="hover" />
        </Body>
      </Container>
    </>
  );
};

export default UserLeaderBoard;
