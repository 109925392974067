import React from 'react';
import loading from '../../assets/animation/loading.json';
import { useTranslation } from 'react-i18next';

const spinePlayerConfig = {
  showControls: false,
  alpha: true,
  backgroundColor: '#00000000',
  animation: 'animation',
  viewport: {
    debugRender: false,
    x: 0,
    y: 0,
    padLeft: '3px',
    padRight: '3px',
    padTop: '3px',
    padBottom: '3px',
  },
};

const PageLoaderV2 = () => {
  const { t } = useTranslation(['common']);

  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    new window.spine.SpinePlayer('flyer-loading-animated', {
      ...loading,
      ...spinePlayerConfig,
    });
  }, []);

  return (
    <div className="wrapper-mover-v2">
      <div className="full-background active"></div>
      <div id="flyer-loading-animated" className="loading-mover active"></div>
      <div className="connect-text">
        <div className="text-1"> {t('noInterNet')}</div>
        <div className="text-2">{t('noInterNet2')}</div>
      </div>
    </div>
  );
};
export default PageLoaderV2;
